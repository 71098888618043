import * as yup from 'yup';

export const SingleStandardScheme = yup.object().shape({
	remark: yup.string().max(1000, ' ').min(1),
});

export const StageUpdateScheme = yup.object().shape({
	comment: yup.string().max(1000, ' ').min(1),
	targetDate: yup.date() || null,
});

export const SingleStandardAttachmentScheme = yup.object().shape({
	attachments: yup.array().of(
		yup.object().shape({
			attachmentDescription: yup.string().max(500, ' '),
			attachmentType: yup.object().shape({
				value: yup.string(),
				label: yup.string(),
			}),
		})
	),
});
