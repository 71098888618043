import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getStandardPublicService } from '@asd-stan/standard/infrastructure/getters';
import { DomainRole } from '@asd-stan/user/domain/domain-role.enum';
import { UserCollectionFilter } from '@asd-stan/user/domain/user.service';
import { getUserService } from '@asd-stan/user/infrastructure/getters';
import { FormSelect } from '@components/form-select/form-select';
import {
	AdditionalType,
	FormSelectAsyncPagination,
} from '@components/form-select/form-select-async-pagination';
import { Field, FormikValues, useFormikContext } from 'formik';

import { ProjectLeader } from '../../general/project-leader/project-leader';

const calculateOffset = (page: number) => {
	return (page - 1) * LIMIT;
};

const LIMIT = 6;

export const Experts: React.FC = observer(() => {
	const { t } = useTranslation();
	const { values, setFieldValue, handleSubmit } = useFormikContext<FormikValues>();

	const userService = getUserService();
	const standardPublicService = getStandardPublicService();

	const handleLoadUsersStandards = async (
		search: string,
		_prevOptions: any,
		{ page }: AdditionalType
	) => {
		const filter: Partial<UserCollectionFilter> = values.domainCodes.reduce(
			(filter: { domainCodes: Array<string> }, code: { label: string; value: string }) => {
				if (!filter.domainCodes) {
					filter.domainCodes = [];
				}
				filter.domainCodes.push(code.value);

				return filter;
			},
			{
				search: !!search ? search : undefined,
			} as Partial<UserCollectionFilter>
		);

		const offset = calculateOffset(page);
		const userList = await standardPublicService.getUsersByDomains(filter, LIMIT, offset);

		const users = userList.data.map(user => {
			return {
				label: `${user.firstName} ${user.lastName}`,
				userLabel: <ProjectLeader user={user} />,
				value: user.id,
			};
		});
		const hasMore = page < Math.ceil(userList.totalNumber / LIMIT);

		return {
			options: users,
			hasMore,
			additional: {
				page: page + 1,
			},
		};
	};

	const domainParticipantsOptions = Array.isArray(userService.domainParticipants)
		? userService.domainParticipants.map(user => {
				return { label: <ProjectLeader user={user} />, value: user.id };
		  })
		: '';

	useEffect(() => {
		const clearWGS = async () => {
			if (values.wgs) {
				if (values.wgs.value === '') {
					return;
				}
				if (Array.isArray(domainParticipantsOptions)) {
					if (domainParticipantsOptions.length === 0) {
						return;
					}
					if (
						domainParticipantsOptions.find(user => user.value === values.wgs.value) === undefined
					) {
						await setFieldValue('wgs', { label: '', value: '' });
						handleSubmit();
					}
				}
			}
		};

		clearWGS();
	}, [domainParticipantsOptions, setFieldValue, values.wgs]);

	useEffect(() => {
		userService.getDomainParticipants(
			values.domainCodes.map(({ value }: { value: string }) => value),
			[],
			['WGS']
		);
	}, [values.domainCodes]);

	return (
		<>
			<Field
				component={FormSelectAsyncPagination}
				name="experts"
				title={t('standard.createNWP.experts.suggestExpertsTitle')}
				defaultOptions
				fullWidth
				isMulti
				loadOptions={handleLoadUsersStandards}
				showCheckboxOption
				disabled={
					values.domainCodes && values.domainCodes[0] && values.domainCodes[0].value !== ''
						? false
						: true
				}
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				useSubmitOnChange
				key={`experts-${JSON.stringify(values.domainCodes)}`}
			/>
			<Field
				component={FormSelect}
				name="wgs"
				title={t('standard.createNWP.experts.secretariatTitle')}
				options={domainParticipantsOptions}
				disabled={
					values.domainCodes && values.domainCodes[0] && values.domainCodes[0].value !== ''
						? false
						: true
				}
				fullWidth
				isLoading={userService.loadDomainParticipants}
				useSubmitOnChange
				key={`wgs-${JSON.stringify(values.domainCodes)}`}
			/>
		</>
	);
});
