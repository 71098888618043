import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import {
	AccessibleDomain,
	AccessibleDomainWorkingGroup,
} from '@asd-stan/domain/domain/domain-access.service';
import { getDraftService } from '@asd-stan/draft/infrastructure/getters';
import {
	userHasDomainRoles,
	userHasRoles,
	userHasWGRoles,
	userLacksRole,
} from '@asd-stan/helpers/app-utils';
import { MultilevelMenuItem } from '@asd-stan/shell/components/sidebar/sidebar-item/multilevel-menu-item';
import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { ReactComponent as BallotsIcon } from '@asd-stan/ui-kit/assets/asd-stan-works/icons/ballots-icon.svg';
import { ReactComponent as DashboardIcon } from '@asd-stan/ui-kit/assets/asd-stan-works/icons/dashboard-icon.svg';
import { ReactComponent as DocumentsIcon } from '@asd-stan/ui-kit/assets/asd-stan-works/icons/documents-icon.svg';
import { ReactComponent as MeetingsIcon } from '@asd-stan/ui-kit/assets/asd-stan-works/icons/meetings-icon.svg';
import { ReactComponent as SettingsIcon } from '@asd-stan/ui-kit/assets/asd-stan-works/icons/settings-icon.svg';
import { ReactComponent as StandardsIcon } from '@asd-stan/ui-kit/assets/asd-stan-works/icons/standards-icon.svg';
import { ReactComponent as UserListIcon } from '@asd-stan/ui-kit/assets/asd-stan-works/icons/userlist-icon.svg';
import { ReactComponent as GoBackArrow } from '@asd-stan/ui-kit/assets/icons/arrow-menu-back.svg';
import { ReactComponent as GoFurtherArrow } from '@asd-stan/ui-kit/assets/icons/dropdown.svg';
import { DomainRole } from '@asd-stan/user/domain/domain-role.enum';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { ReactComponent as LogoIcon } from '@assets/sidebar-logo.svg';
import { Loader } from '@components/loader/loader';
import { Flex } from '@components/utility/flex';

import { SidebarItem } from './sidebar-item/sidebar-item';
import { StyledSidebar } from './sidebar.styled';

import { StyledLogo } from '@components/logo/logo.styled';

interface SidebarProps {
	domains: AccessibleDomain[];
	loadingDomains: boolean;
}

interface MenuLevelItem {
	name: string;
	code: string;
	link: string;
	count?: number;
}

export const Sidebar: React.FC<SidebarProps> = observer(({ domains, loadingDomains }) => {
	const location = useLocation();
	const { t } = useTranslation();
	const params = useParams();
	const draftService = getDraftService();
	const standardService = getStandardService();
	const currentUserService = getCurrentUserService();

	const checkActiveTab = () => {
		if (location.pathname.search('/standards') === 0) {
			if (params.domain && params.domain !== 'assign' && params.domain !== 'draft') {
				return params.domain;
			} else {
				return 'Standards';
			}
		} else {
			return 'main';
		}
	};

	const [active, setActive] = useState(checkActiveTab());

	const canSeeWholeMenu = !currentUserService.isOnlyExpert;

	const sidebarItems = [
		{ icon: <DashboardIcon />, name: t('common.sidebar.dashboard'), link: '/dashboard' },
		{
			icon: <StandardsIcon />,
			name: t('common.sidebar.standards.title'),
			link: '/standards',
		},
		{ icon: <BallotsIcon />, name: t('common.sidebar.ballots'), link: '/ballots' },
		{ icon: <MeetingsIcon />, name: t('common.sidebar.meetings'), link: '/meetings' },
		...(userHasRoles(
			[SystemRole.ADMIN, SystemRole.DIRECTOR, SystemRole.ES],
			currentUserService.userRoles!
		)
			? [
					{
						icon: <UserListIcon />,
						name: t('common.sidebar.userList'),
						link: '/user-list',
					},
			  ]
			: []),
		{ icon: <DocumentsIcon />, name: t('common.sidebar.documents'), link: '/documents' },
	];

	const createDomainMap = () => {
		const mapDomains = (domain: AccessibleDomain) => {
			return { name: `${domain.code} ${domain.name}`, code: domain.code } as MenuLevelItem;
		};

		const links = [];

		if (canSeeWholeMenu && !loadingDomains) {
			// Show "all standards" link for allowed roles
			links.push({
				name: t('common.sidebar.standards.allStandards'),
				code: '',
				link: '/standards',
			});
		}

		// Show "catalog" link for allowed roles
		if (
			(userHasRoles(SystemRole.BOARD_MEMBER, currentUserService.userRoles!) &&
				userHasDomainRoles(DomainRole.FP, currentUserService.domainParticipations)) ||
			(userHasRoles(SystemRole.EXPERT, currentUserService.userRoles!) &&
				userHasDomainRoles(
					[DomainRole.DS, DomainRole.WGS, DomainRole.FP],
					currentUserService.domainParticipations
				)) ||
			userHasWGRoles('WGS', currentUserService.domainParticipations) ||
			userHasRoles(
				[SystemRole.ES, SystemRole.ADMIN, SystemRole.DIRECTOR, SystemRole.MC],
				currentUserService.userRoles!
			)
		) {
			links.push({
				name: t('common.sidebar.standards.catalogueItems'),
				code: '',
				link: '/standards/catalog',
			});
		}

		// Show "assign standard allocation" link for allowed roles
		if (canSeeWholeMenu && !loadingDomains) {
			if (standardService.allocationCount) {
				links.push({
					name: t('common.sidebar.standards.assignStandardAllocation'),
					code: '',
					link: '/standards/assign',
					count: standardService.allocationCount,
				});
			}
		}

		// Show "drafts" link for allowed roles
		if (
			userLacksRole([SystemRole.EXPERT], currentUserService.userRoles!) ||
			currentUserService.domainParticipations.length > 0
		) {
			if (draftService.draftsCount) {
				links.push({
					name: t('common.sidebar.standards.drafts'),
					code: '',
					link: '/standards/draft',
					count: draftService.draftsCount,
				});
			}
		}

		// Show domain links for allowed roles
		let allowedDomains = domains;

		if (currentUserService.hasOnlyRole(SystemRole.EXPERT)) {
			allowedDomains = allowedDomains.filter(({ code }) =>
				currentUserService.checkIfParticipateInDomain(code)
			);
		}

		links.push(
			...allowedDomains.map(domain => {
				return mapDomains(domain);
			})
		);

		// Show "add new" link for allowed roles
		if (links.length === 0 && !loadingDomains) {
			links.push({
				name: t('common.sidebar.standards.addNew'),
				code: '',
				link: `/standards/new-standard`,
			});
		}

		return links;
	};

	const createWGMap = (domain: AccessibleDomain) => {
		const mapWG = (workingGroup: AccessibleDomainWorkingGroup) => {
			return {
				name: `${workingGroup.code} ${workingGroup.name}`,
				code: workingGroup.code,
				link: `standards/${workingGroup.code || 'new-standard'}`,
			};
		};

		let allowedWGs = domain.workingGroups;
		const hasOnlyExpertRole = currentUserService.hasOnlyRole(SystemRole.EXPERT);
		const ableToSeeAllDomain = currentUserService.checkIfAbleToSeeAllDomain(domain.code);

		if (
			hasOnlyExpertRole &&
			!ableToSeeAllDomain
		) {
			allowedWGs = allowedWGs.filter(({ code }) =>
				currentUserService.checkIfParticipateInWG(domain.code, code)
			);
		}

		const links = allowedWGs.map(mapWG);

		if (!hasOnlyExpertRole || ableToSeeAllDomain) {
			links.unshift({
				name: t('common.sidebar.standards.allStandardsInDomain'),
				code: '',
				link: `standards/${domain.code}`,
			});
		}
		return links;
	};

	const checkIfLinkIsActive = (link: string): string | undefined => {
		const splitLink = link?.split('/');
		const currentPosition = splitLink && splitLink[splitLink.length - 1];

		if (`standards/${params.domain}` === link && !params.workingGroup) {
			return 'active';
		} else if (location.pathname === link) {
			return 'active';
		} else if (
			params.workingGroup &&
			currentPosition === params.workingGroup &&
			splitLink[splitLink.length - 2] === params.domain
		) {
			return 'active';
		}
	};

	const generateMenuLevel = (linkName: string, content: MenuLevelItem[], previousPage: string) => {
		return (
			<CSSTransition
				in={active === linkName}
				unmountOnExit
				timeout={500}
				classNames="menu-secondary">
				<div className="menu">
					<MultilevelMenuItem
						className="menu-go-back"
						icon={<GoBackArrow />}
						goToMenu={previousPage}
						setActive={setActive}
						menuGoBack>
						{linkName}
					</MultilevelMenuItem>
					{loadingDomains ? (
						<Flex $align="center" $justify="center" $direction="column">
							<Loader />
						</Flex>
					) : (
						<>
							{content.map((item: MenuLevelItem, index: number) => {
								const isActive = checkIfLinkIsActive(item.link);
								return (
									<MultilevelMenuItem
										key={`menu-item-${index}`}
										className={isActive}
										icon={item.link ? '' : <GoFurtherArrow />}
										goToMenu={item.link ? undefined : item.code}
										count={item.count}
										link={item.link}
										setActive={setActive}>
										{item.name}
									</MultilevelMenuItem>
								);
							})}
						</>
					)}
				</div>
			</CSSTransition>
		);
	};

	return (
		<StyledSidebar>
			<div>
				<StyledLogo to="/dashboard">
					<LogoIcon />
				</StyledLogo>
				<ul>
					<CSSTransition
						in={active === 'main'}
						unmountOnExit
						timeout={500}
						classNames="menu-primary">
						<div className="menu">
							{sidebarItems.map((item, index) => {
								if (item.name === t('common.sidebar.standards.title')) {
									return (
										<MultilevelMenuItem
											key={`menu-item-${index}`}
											link="#"
											goToMenu={'Standards'}
											setActive={setActive}
											icon={item.icon}
											mainMenu>
											{item.name}
										</MultilevelMenuItem>
									);
								} else {
									return (
										<SidebarItem
											key={`menu-item-${index}`}
											link={item.link}
											icon={item.icon}
											name={item.name}
										/>
									);
								}
							})}
							{userLacksRole([SystemRole.EXPERT], currentUserService.userRoles!) ||
							userHasDomainRoles(
								['WGS', 'WGC', 'Domain Secretary'],
								currentUserService.domainParticipations!
							) ? (
								<SidebarItem
									link="/config"
									className="config-menu-item"
									icon={<SettingsIcon />}
									name={t('common.sidebar.configuration')}
								/>
							) : null}
						</div>
					</CSSTransition>
					{generateMenuLevel(t('common.sidebar.standards.title'), createDomainMap(), 'main')}
					{domains.map(domain => {
						return (
							<React.Fragment key={domain.code}>
								{generateMenuLevel(
									domain.code,
									createWGMap(domain),
									t('common.sidebar.standards.title')
								)}
							</React.Fragment>
						);
					})}
				</ul>
			</div>
		</StyledSidebar>
	);
});
