import moment from 'moment';

import { meetingTypeOptions } from '../components/meeting-form/forms/general';
import { MeetingType, SingleMeeting, ValidatedMeetingForm } from '../domain/meeting.entity';

interface MeetingFormDto {
	title: string;
	domains: string[];
	workingGroups: string[];
	type: 'remote' | 'physical';
	startTime: string;
	endTime: null | string;
	link: string;
	location: string;
	standardId: null | number;
	attachments: {
		fileId: number;
		description: string;
	}[];
}

const timeFormat = 'YYYY-MM-DD\\THH:mm:ss';

export const mapMeetingDto = ({
	title,
	domains,
	workingGroups,
	type,
	startTime,
	endTime,
	link,
	location,
	standard,
	files,
}: ValidatedMeetingForm): MeetingFormDto => ({
	title,
	domains: domains.map(({ value }) => value),
	workingGroups: workingGroups.map(({ value }) => value),
	type: type.value,
	startTime: moment(startTime).format(timeFormat),
	endTime: endTime ? moment(endTime).format(timeFormat) : null,
	link,
	location,
	standardId: standard ? standard.value : null,
	attachments: files.map(({ file, description }) => ({
		fileId: file.fileId,
		description: description,
	})),
});

interface MeetingDto {
	domains: {
		code: string;
		name: string;
	}[];
	workingGroups: {
		code: string;
		name: string;
	}[];
	title: string;
	type: MeetingType;
	startTime: string;
	endTime: null | string;
	link: null | string;
	location: string;
	standard: null | {
		id: number;
		registrationNumber: null | string;
		form: null | string;
		edition: null | string;
		title: null | string;
	};
	attachments: {
		id: number;
		file: {
			id: number;
			path: null | string;
			name: string;
			size: number;
		};
		createdAt: string;
		createdBy: {
			firstName: string;
			lastName: string;
		};
		description: null | string;
	}[];
	createdBy: null | {
		id: number;
	};
}

export const mapSingleMeeting = ({
	domains,
	workingGroups,
	title,
	type,
	startTime,
	endTime,
	link,
	location,
	standard,
	attachments,
	createdBy,
}: MeetingDto): SingleMeeting => ({
	form: {
		domains: domains.map(({ code, name }) => ({ label: `${code} ${name}`, value: code })),
		workingGroups: workingGroups.map(({ code, name }) => ({
			label: `${code} ${name}`,
			value: code,
		})),
		title,
		type: meetingTypeOptions.find(({ value }) => value === type)!,
		startTime: new Date(startTime.split('+')[0]),
		endTime: endTime ? new Date(endTime.split('+')[0]) : null,
		link: link || '',
		location,
		standard: standard
			? {
					label: `${standard.registrationNumber ?? ''} ${standard.form ?? ''} ${
						standard.edition ?? ''
					} ${standard.title ?? ''}`,
					value: standard.id,
			  }
			: null,
		files: attachments.map(({ id, file, description }) => ({
			description: description || '',
			file: {
				id,
				fileId: file.id,
				path: file.path || '',
				size: file.size,
				title: file.name,
			},
		})),
	},
	filesMeta: attachments.map(({ createdAt, createdBy, id }) => ({
		id,
		createdBy,
		createdAt: new Date(createdAt),
	})),
	standard: standard
		? {
				id: standard.id,
				label: `${standard.registrationNumber ?? ''} ${standard.form ?? ''} ${
					standard.edition ?? ''
				} ${standard.title ?? ''}`,
		  }
		: null,
	createdById: createdBy?.id ?? null,
});
