import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Originator as OriginatorData } from '@asd-stan/standard/domain/standard-detailed.entity';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { Avatar } from '@components/avatar/avatar';

import {
	OriginatorContainer,
	OriginatorDeleted,
	OriginatorEmail,
	OriginatorHeader,
	OriginatorInfo,
	OriginatorName,
	OriginatorStatus,
	OriginatorWrapper,
} from './originator-components.styled';

interface Props {
	originator: OriginatorData;
}

export const Originator: FC<Props> = props => {
	const { t } = useTranslation();

	const originatorOutOfSystem = !props.originator.userId;
	const deleted = !!props.originator.deletedAt;

	const renderOriginatorHeader = () => (
		<OriginatorHeader>
			<h4>{t('standard.singleStandardPage.originator.title')}</h4>
			{originatorOutOfSystem || deleted ? (
				<OriginatorStatus>
					{t('standard.singleStandardPage.originator.outOfTheSystem')}
				</OriginatorStatus>
			) : (
				<OriginatorStatus>
					{t('standard.singleStandardPage.originator.inTheSystem')}
				</OriginatorStatus>
			)}
		</OriginatorHeader>
	);

	const renderViewAllStandards = () => {
		return null;
		// if (originatorOutOfSystem) {
		// 	return hasRoleForOriginatorOutOfSystem ? (
		// 		<Button
		// 			disabled
		// 			icon={
		// 				<OriginatorViewLinksIcon>
		// 					<LinkIcon />
		// 				</OriginatorViewLinksIcon>
		// 			}
		// 			secondary
		// 			title={t('standard.singleStandardPage.originator.viewStandards')}
		// 			onClick={() => null}
		// 		/>
		// 	) : null;
		// }

		// return hasRoleForOriginatorInSystem ? (
		// 	<Button
		// 		icon={
		// 			<OriginatorViewLinksIcon>
		// 				<LinkIcon />
		// 			</OriginatorViewLinksIcon>
		// 		}
		// 		disabled
		// 		secondary
		// 		title={t('standard.singleStandardPage.originator.viewStandards')}
		// 		onClick={() => null}
		// 	/>
		// ) : null;
	};

	return (
		<InputGroup titleComponent={renderOriginatorHeader()}>
			<OriginatorContainer>
				<OriginatorWrapper>
					{!originatorOutOfSystem ? (
						<Avatar
							uri={props.originator.picture && props.originator.picture.path}
							deleted={deleted}
						/>
					) : null}
					<OriginatorInfo deleted={deleted}>
						<div className="block">
							<OriginatorName className="name">
								{props.originator.firstName} {props.originator.lastName}{' '}
							</OriginatorName>
							{deleted && (
								<OriginatorDeleted>
									{t('standard.singleStandardPage.originator.deletedUser')}
								</OriginatorDeleted>
							)}
						</div>
						<OriginatorEmail className="email">{props.originator.email}</OriginatorEmail>
					</OriginatorInfo>
				</OriginatorWrapper>
				{renderViewAllStandards()}
			</OriginatorContainer>
		</InputGroup>
	);
};
