import React from 'react';

import { Counter } from '@asd-stan/shell/components/header/counter/counter';

import { StyledPageTitle } from './page-title.styled';

interface PageTitleProps {
	title: string;
	count?: number;
	countName?: string;
	className?: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({ title, count, countName, className }) => {
	return (
		<StyledPageTitle className={className} title={title}>
			{title}
			{count && countName ? <Counter count={count} name={countName} /> : null}
		</StyledPageTitle>
	);
};
