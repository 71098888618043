import { Company } from '@asd-stan/user/domain/company.entity';
import { Country } from '@asd-stan/user/domain/country.entity';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { getUserRepo } from '@asd-stan/user/infrastructure/getters';
import { DateTime } from 'luxon';
import { makeAutoObservable } from 'mobx';

import { Position } from './position.entity';

interface UserArgs {
	id: number;
	userId: number;
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	available: boolean;
	appointerId: number;
	appointer: User | null;
	invitedById: number;
	invitedBy: User | null;
	country: Country | null;
	company: Company | null;
	systemRoles: SystemRole[];
	createdAt: DateTime;
	updatedAt: DateTime;
	positions: Position[];
	isAppointerLoaded: boolean;
	isInvitedByLoaded: boolean;
	deletedAt: null | string;
}

export class User {
	private _id: number;
	private _userId: number;
	private _firstName: string;
	private _lastName: string;
	private _phone: string;
	private _email: string;
	private _available: boolean;
	private _country: Country | null;
	private _company: Company | null;
	private _systemRoles: SystemRole[];
	private _createdAt: DateTime;
	private _updatedAt: DateTime;
	private _positions: Position[];
	deletedAt: null | string;

	constructor(args: UserArgs) {
		makeAutoObservable(this);

		this._id = args.id;
		this._userId = args.userId;
		this._firstName = args.firstName;
		this._lastName = args.lastName;
		this._phone = args.phone;
		this._email = args.email;
		this._available = args.available;
		this._country = args.country;
		this._company = args.company;
		this._systemRoles = args.systemRoles;
		this._createdAt = args.createdAt;
		this._updatedAt = args.updatedAt;
		this._positions = args.positions;
		this.deletedAt = args.deletedAt;
	}

	// getters

	private get _userRepo() {
		return getUserRepo();
	}

	get id() {
		return this._id;
	}

	get userId() {
		return this._userId;
	}

	get firstName() {
		return this._firstName;
	}

	get lastName() {
		return this._lastName;
	}

	get fullName() {
		return this._firstName + ' ' + this._lastName;
	}

	get phone() {
		return this._phone;
	}

	get email() {
		return this._email;
	}

	get available() {
		return this._available;
	}

	get country() {
		return this._country;
	}

	get company() {
		return this._company;
	}

	get systemRoles() {
		return this._systemRoles;
	}

	get createdAt() {
		return this._createdAt;
	}

	get updatedAt() {
		return this._updatedAt;
	}

	get positions() {
		return this._positions;
	}

	// getters end
	// methods

	// methods end
}
