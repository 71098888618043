import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileUploadController } from '@asd-stan/file/domain/file-upload-controller';
import { FileDropzone } from '@components/file-dropzone/file-dropzone';
import { FormInputPopup } from '@components/form-input-popup/form-input-popup';
import { Toggle } from '@components/toggle/toggle';
import { Field, FormikValues, useFormikContext } from 'formik';

import { DownloadFile } from '../download-file/download-file';

import { StyledPatentFile } from './patent-file.styled';

const fileTypes = {
	'application/msword': ['.doc'],
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
	'application/vnd.ms-excel': ['.xls'],
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
	'application/pdf': ['.pdf'],
	'application/zip': ['.zip'],
};

export const PatentFile: React.FC = () => {
	const url = window.location.href;
	const { t } = useTranslation();

	const { values, setFieldValue, handleSubmit } = useFormikContext<FormikValues>();
	const fileUploadController = new FileUploadController();
	const [knownPatents, setKnownPatents] = useState<boolean>(values.knownPatentIssue);

	const handleKnownPatents = async () => {
		if (!knownPatents === false) {
			setFieldValue('patentReferences', '');
			setFieldValue('patentFile', []);
		}
		setFieldValue('knownPatentIssue', !knownPatents);
		await setKnownPatents(!knownPatents);
		handleSubmit();
	};

	return (
		<StyledPatentFile>
			<Field
				component={Toggle}
				name="knownPatentIssue"
				checked={knownPatents}
				onChange={handleKnownPatents}
				label={t('standard.createNWP.justification.knownPatents')}
			/>

			{values.knownPatentIssue === true && (
				<>
					<Field
						component={FormInputPopup}
						name="patentReferences"
						title={t('standard.createNWP.justification.patentReferences')}
						fullWidth
						disabled={Array.isArray(values.patentFile) && values.patentFile.length !== 0}
						mandatory={Array.isArray(values.patentFile) && values.patentFile.length === 0}
						showError
						maxLength={5000}
						useSubmitOnBlur
						disableMaxLength={url.includes('draft')}
					/>
					<p
						className="patent-title"
						dangerouslySetInnerHTML={{
							__html: t('standard.createNWP.justification.uploadTitle'),
						}}
					/>
					<DownloadFile file="Template for Essential Patents and other statutory IPRs declaration.docx" />
					<Field
						component={FileDropzone}
						disabled={values.patentReferences}
						name="patentFile"
						fileTypes={fileTypes}
						maxFiles={1}
						maxSize={104857600}
						multiple
						controller={fileUploadController}
						useSubmitOnDrop
					/>
				</>
			)}
		</StyledPatentFile>
	);
};
