import { observer } from 'mobx-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { signInRedirectParamKey } from '@asd-stan/auth/domain/constants';
import { getAuthService } from '@asd-stan/auth/infrastructure/getters';
import { Button } from '@asd-stan/ui-kit/components/button/button';
import { FormError } from '@asd-stan/ui-kit/components/form-error/form-error';
import { FormInputPassword } from '@asd-stan/ui-kit/components/form-input-password/form-input-password';
import { FormInput } from '@components/form-input/form-input';
import { Field, Form, Formik, FormikValues } from 'formik';

import validationSchema from '../../validation-schemas/sign-in.schema';
import { AuthLayout } from '../layout/auth-layout/auth-layout';

export const SignIn: React.FC = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const authService = getAuthService();

	const [isError, setIsError] = useState<boolean>(false);
	const [loadingRequest, setLoadingRequest] = useState(false);

	const initialValues = {
		email: '',
		password: '',
	};

	const onSubmit = async (values: FormikValues) => {
		try {
			setLoadingRequest(true);
			await authService.login(values.email, values.password);
			const redirectTo = searchParams.get(signInRedirectParamKey);
			if (redirectTo) {
				navigate(redirectTo);
			} else {
				navigate('/dashboard');
			}
			setLoadingRequest(false);
		} catch (error) {
			setIsError(true);
			setLoadingRequest(false);
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validationSchema}
			validateOnBlur={false}
			validateOnChange={false}>
			{({ errors, handleSubmit }) => (
				<AuthLayout>
					<h1>{t('auth.signin.title')}</h1>
					{(errors.password || errors.email || isError) && (
						<FormError title={t('auth.signin.errors.incorrect')} />
					)}
					<Form>
						<Field
							component={FormInput}
							name="email"
							title={t('auth.signin.emailTitle')}
							placeholder={t('auth.signin.emailPlaceholder')}
							fullWidth
						/>
						<Field
							component={FormInputPassword}
							name="password"
							title={t('auth.signin.passwordTitle')}
							placeholder={t('auth.signin.passwordPlaceholder')}
							forgot
							handleSubmit={handleSubmit}
						/>
					</Form>
					<Button
						title={t('auth.signin.signinButton')}
						onClick={handleSubmit}
						disabled={loadingRequest}
						fullWidth
					/>
				</AuthLayout>
			)}
		</Formik>
	);
});
