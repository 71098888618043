import { gql } from '@apollo/client';
import { stanWorksClient } from '@asd-stan/config/api';

import {
	BallotListFilter,
	ValidatedBallotForm,
	ValidatedVotingForm,
} from '../domain/ballot.entity';

import { mapBallotForm, mapCreateUpdateBallotDto } from './ballot-form.mapper';
import { mapBallotList, mapBallotListFilter } from './ballot-list.mapper';
import { mapBallotStats } from './ballot-stats.mapper';
import { mapCreateUpdateVoteDto, mapSingleBallot } from './single-ballot.mapper';
import { mapVoteList } from './vote-list.mapper';

const CREATE_BALLOT = gql`
	mutation ($ballot: BallotInput!) {
		createBallot(ballot: $ballot) {
			id
		}
	}
`;

const BALLOT_LIST = gql`
	query ($limit: Int!, $offset: Int!, $filter: BallotFilterInput) {
		ballotList(limit: $limit, offset: $offset, filter: $filter) {
			id
			title
			description
			status
			openingDate
			closingDate
			result
			resultComment
			createdBy {
				id
			}
		}

		ballotCount(filter: $filter)
	}
`;

const BALLOT_FORM = gql`
	query ($id: Int!) {
		ballot(id: $id) {
			title
			description
			status
			standard {
				id
				registrationNumber
				form
				edition
				title
			}
			openingDate
			closingDate
			selectedVoters {
				id
				firstName
				lastName
				deletedAt
			}
			attachments {
				id
				file {
					id
					name
					path
					size
				}
			}
			result
			resultComment
			questions {
				id
				title
				comment
			}
			createdBy {
				id
			}
		}
	}
`;

const SINGLE_BALLOT = gql`
	query ($id: Int!) {
		ballot(id: $id) {
			title
			description
			status
			standard {
				id
				registrationNumber
				form
				edition
				title
			}
			openingDate
			closingDate
			selectedVoters {
				id
				firstName
				lastName
			}
			attachments {
				id
				createdAt
				createdBy {
					firstName
					lastName
				}
				file {
					id
					name
					path
					size
				}
			}
			result
			resultLastAction {
				doneBy {
					firstName
					lastName
				}
				doneAt
			}
			resultComment
			resultCommentLastAction {
				doneBy {
					firstName
					lastName
				}
				doneAt
			}
			questions {
				id
				title
				comment
			}

			createdBy {
				id
			}
		}

		myVote(ballotId: $id) {
			id
			organization {
				id
				name
			}
			attachments {
				id
				file {
					id
					name
					path
					size
				}
			}
			decisions {
				id
				question {
					id
				}
				decision
				comment
			}
		}
	}
`;

const UPDATE_BALLOT = gql`
	mutation ($id: Int!, $ballot: BallotInput!) {
		updateBallot(id: $id, ballot: $ballot) {
			id
		}
	}
`;

const DELETE_BALLOT = gql`
	mutation ($id: Int!) {
		deleteBallot(id: $id)
	}
`;

const BALLOT_ATTACHMENT_FILE = gql`
	query ($id: Int!) {
		ballotAttachmentFile(id: $id)
	}
`;

const CREATE_VOTE = gql`
	mutation ($vote: BallotVoteInput!) {
		createVote(vote: $vote) {
			id
		}
	}
`;

const UPDATE_VOTE = gql`
	mutation ($id: Int!, $vote: BallotVoteInput!) {
		updateVote(id: $id, vote: $vote) {
			id
		}
	}
`;

const VOTE_LIST = gql`
	query ($limit: Int!, $offset: Int!, $ballotId: Int!) {
		voteList(limit: $limit, offset: $offset, ballotId: $ballotId) {
			id
			createdBy {
				firstName
				lastName
				picture {
					path
				}
			}
			updatedAt
			organization {
				name
			}
			attachments {
				id
				createdAt
				createdBy {
					firstName
					lastName
				}
				file {
					id
					name
					path
					size
				}
			}
			decisions {
				id
				question {
					title
					comment
				}
				decision
				comment
			}
		}

		voteCount(ballotId: $ballotId)
	}
`;

const VOTE_ATTACHMENT_FILE = gql`
	query ($id: Int!) {
		voteAttachmentFile(id: $id)
	}
`;

const BALLOT_STATS = gql`
	query ($ballotId: Int!) {
		ballotStats(ballotId: $ballotId) {
			question {
				id
				title
			}
			result
			totalVoted
			allowedToVote
			approve
			disapprove
			abstain
		}
	}
`;

export class BallotRepo {
	async createBallot(ballotValues: ValidatedBallotForm) {
		const ballot = mapCreateUpdateBallotDto(ballotValues);
		await stanWorksClient.mutate({
			mutation: CREATE_BALLOT,
			variables: {
				ballot,
			},
		});
	}

	async getBallotList(limit: number, offset: number, filterValues: Partial<BallotListFilter>) {
		const filter = mapBallotListFilter(filterValues);
		const { data } = await stanWorksClient.query({
			query: BALLOT_LIST,
			variables: {
				limit,
				offset,
				filter,
			},
		});
		return mapBallotList(data);
	}

	async getSingleBallotToUpdate(id: number) {
		const { data } = await stanWorksClient.query({
			query: BALLOT_FORM,
			variables: {
				id,
			},
		});
		return mapBallotForm(data.ballot);
	}

	async getSingleBallot(id: number) {
		const { data } = await stanWorksClient.query({
			query: SINGLE_BALLOT,
			variables: {
				id,
			},
		});
		return mapSingleBallot(data);
	}

	async updateBallot(id: number, ballotValues: ValidatedBallotForm) {
		const ballot = mapCreateUpdateBallotDto(ballotValues);
		await stanWorksClient.mutate({
			mutation: UPDATE_BALLOT,
			variables: {
				id,
				ballot,
			},
		});
	}

	async deleteBallot(id: number) {
		await stanWorksClient.mutate({
			mutation: DELETE_BALLOT,
			variables: {
				id,
			},
		});
	}

	async getBallotAttachmentFileUrl(id: number): Promise<string> {
		const { data } = await stanWorksClient.query({
			query: BALLOT_ATTACHMENT_FILE,
			variables: {
				id,
			},
		});
		return data.ballotAttachmentFile;
	}

	async createVote(ballotId: number, voteValues: ValidatedVotingForm) {
		const vote = mapCreateUpdateVoteDto(ballotId, voteValues);
		await stanWorksClient.mutate({
			mutation: CREATE_VOTE,
			variables: {
				vote,
			},
		});
	}

	async updateVote(id: number, ballotId: number, voteValues: ValidatedVotingForm) {
		const vote = mapCreateUpdateVoteDto(ballotId, voteValues);
		await stanWorksClient.mutate({
			mutation: UPDATE_VOTE,
			variables: {
				id,
				vote,
			},
		});
	}

	async getVoteList(ballotId: number, limit: number, offset: number) {
		const { data } = await stanWorksClient.query({
			query: VOTE_LIST,
			variables: {
				limit,
				offset,
				ballotId,
			},
		});
		return mapVoteList(data);
	}

	async getVoteAttachmentFileUrl(id: number): Promise<string> {
		const { data } = await stanWorksClient.query({
			query: VOTE_ATTACHMENT_FILE,
			variables: {
				id,
			},
		});
		return data.voteAttachmentFile;
	}

	async getBallotStats(ballotId: number) {
		const { data } = await stanWorksClient.query({
			query: BALLOT_STATS,
			variables: {
				ballotId,
			},
		});
		return mapBallotStats(data.ballotStats);
	}
}
