import { gql } from '@apollo/client';
import { stanWorksClient } from '@asd-stan/config/api';
import { Page } from '@asd-stan/helpers/pagination-collection-controller';
import { UserDTO } from '@asd-stan/user/api/user.dto';
import { makeUserFromUserDTO, makeUserInvitationDTO } from '@asd-stan/user/api/user.factory';
import { UserInvitation } from '@asd-stan/user/domain/user-invitation';
import { User } from '@asd-stan/user/domain/user.entity';
import { UserCollectionFilter } from '@asd-stan/user/domain/user.service';

import { SingleUser, UserDto, mapUser, mapUserToDto } from './single-user.mapper';

const INVITE_USER = gql`
	mutation InviteUser(
		$user: UserInput!
		$domainParticipations: [DomainParticipationInput!]!
		$workingGroupParticipations: [WorkingGroupParticipationInput!]!
	) {
		inviteUser(
			user: $user
			domainParticipations: $domainParticipations
			workingGroupParticipations: $workingGroupParticipations
		) {
			id
			firstName
			lastName
			email
		}
	}
`;

const GET_USER_LIST = gql`
	query ($limit: Int!, $offset: Int!, $filter: UserFilterInput) {
		userList(limit: $limit, offset: $offset, filter: $filter) {
			id
			firstName
			lastName
			email
			phone
			systemRoles
			company {
				id
				name
			}
			country {
				id
				name
			}
			deletedAt
		}
		usersCount(filter: $filter)
	}
`;

const GET_DOMAIN_PARTICIPANTS = gql`
	query ($domainCodes: [String!]!, $domainRoles: [String!], $workingGroupRoles: [String!]) {
		domainParticipants(
			domainCodes: $domainCodes
			domainRoles: $domainRoles
			workingGroupRoles: $workingGroupRoles
		) {
			id
			firstName
			lastName
			email
			phone
			systemRoles
			company {
				name
			}
			country {
				name
			}
		}
	}
`;

const GET_USER_BY_ID = gql`
	query ($id: Int!) {
		domainParticipationsByUserId(userId: $id) {
			domain {
				code
				name
			}
			workingGroupParticipations {
				workingGroup {
					code
					name
				}
				roles
			}
			roles
			seeAllDomain
		}

		userById(id: $id) {
			access
			id
			firstName
			lastName
			email
			phone
			systemRoles
			appointedBy {
				id
				name
			}
			company {
				id
				name
			}
			country {
				id
				name
			}
			positions {
				id
				name
			}
		}
	}
`;

const UPDATE_USER = gql`
	mutation (
		$id: Int!
		$user: UserInput!
		$domainParticipations: [DomainParticipationInput!]!
		$workingGroupParticipations: [WorkingGroupParticipationInput!]!
	) {
		updateUser(
			id: $id
			user: $user
			domainParticipations: $domainParticipations
			workingGroupParticipations: $workingGroupParticipations
		) {
			id
		}
	}
`;

const RESEND_INVITATION = gql`
	mutation ($userId: Int!) {
		resendInvitation(userId: $userId)
	}
`;

const INIT_USERS_EXPORT = gql`
	mutation ($filter: UserFilterInput) {
		initExportUsers(filter: $filter)
	}
`;

const CHECK_USERS_EXPORT = gql`
	query ($id: String!) {
		exportUsers(id: $id) {
			progress
			completed
		}
	}
`;

const EXPORT_USER_FILE = gql`
	query ($id: String!) {
		exportUserFile(id: $id)
	}
`;

const CANCEL_USERS_FILE = gql`
	mutation ($id: String!) {
		cancelExportUsers(id: $id)
	}
`;

const DELETE_USER = gql`
	mutation ($id: Int!) {
		deleteUser(id: $id)
	}
`;

export class UserRepo {
	async sendUserInvitation(invitation: UserInvitation): Promise<number> {
		const request = makeUserInvitationDTO(invitation);

		const res = await stanWorksClient.mutate({
			mutation: INVITE_USER,
			variables: {
				user: request.user,
				domainParticipations: request.domainParticipations,
				workingGroupParticipations: request.workingGroupParticipations,
			},
		});

		if (res.errors) {
			throw new Error(res.errors[0].message);
		}

		return res.data;
	}

	async getUserList(
		limit: number,
		offset: number,
		filter?: Partial<UserCollectionFilter>
	): Promise<Page<User>> {
		const { data } = await stanWorksClient.query({
			query: GET_USER_LIST,
			variables: {
				limit: limit,
				offset: offset,
				filter: filter,
			},
		});

		const newData = data.userList.map((user: UserDTO) => {
			return makeUserFromUserDTO(user);
		});

		return { totalNumber: data.usersCount, data: newData };
	}

	async getDomainParticipants(
		domainCodes: Array<string>,
		domainRoles?: Array<string>,
		workingGroupRoles?: Array<string>
	): Promise<Array<User>> {
		const { data } = await stanWorksClient.query({
			query: GET_DOMAIN_PARTICIPANTS,
			variables: {
				domainCodes: domainCodes,
				domainRoles: domainRoles,
				workingGroupRoles: workingGroupRoles,
			},
		});

		return data.domainParticipants;
	}

	async getUser(id: number) {
		const { data } = await stanWorksClient.query<UserDto>({
			query: GET_USER_BY_ID,
			variables: {
				id,
			},
		});
		return mapUser(data);
	}

	async updateUser(id: number, user: SingleUser) {
		const userDto = mapUserToDto(user);
		const { errors } = await stanWorksClient.mutate({
			mutation: UPDATE_USER,
			variables: {
				id,
				...userDto,
			},
		});
		if (!errors) {
			return;
		}
		const errorMessage = errors[0].message;
		throw new Error(errorMessage);
	}

	async resendInvitation(userId: number) {
		await stanWorksClient.mutate({
			mutation: RESEND_INVITATION,
			variables: {
				userId,
			},
		});
	}

	async initUsersExport(filter: UserCollectionFilter) {
		const { data } = await stanWorksClient.mutate({
			mutation: INIT_USERS_EXPORT,
			variables: {
				filter,
			},
		});
		return data.initExportUsers as string;
	}

	async checkUsersExport(id: string) {
		const { data } = await stanWorksClient.query({
			query: CHECK_USERS_EXPORT,
			variables: {
				id,
			},
		});
		return data.exportUsers as { progress: number; completed: boolean };
	}

	async getExportUsersFileLink(id: string) {
		const { data } = await stanWorksClient.query({
			query: EXPORT_USER_FILE,
			variables: {
				id,
			},
		});
		return data.exportUserFile as string;
	}

	async cancelUsersExport(id: string) {
		await stanWorksClient.mutate({
			mutation: CANCEL_USERS_FILE,
			variables: {
				id,
			},
		});
	}

	async deleteUser(id: number) {
		await stanWorksClient.mutate({
			mutation: DELETE_USER,
			variables: {
				id,
			},
		});
	}
}
