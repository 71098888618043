import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { ExportController } from '@asd-stan/helpers/export-controller';
import { PaginationCollectionController } from '@asd-stan/helpers/pagination-collection-controller';
import { UserInvitation } from '@asd-stan/user/domain/user-invitation';
import { User } from '@asd-stan/user/domain/user.entity';
import { getUserRepo } from '@asd-stan/user/infrastructure/getters';
import { IdsFilter } from '@components/content-table/content-table';
import { makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment';

import { SingleUser, UserInfo } from '../api/single-user.mapper';

import { SystemRole } from './system-role.entity';

export interface UserCollectionFilter {
	domainCodes?: Array<string>;
	systemRoles?: string[];
	phone?: string;
	email?: string;
	ids?: null | number[];
	search?: string;
	withDeleted?: boolean;
}

export class UserService {
	private _users: PaginationCollectionController<User, UserCollectionFilter>;
	private _domainParticipants: Array<User> | string = [];
	private _originators: Array<User> = [];
	private _loadDomainParticipants: boolean = false;
	usersExport = new ExportController(
		this._userRepo.initUsersExport,
		this._userRepo.cancelUsersExport,
		this._userRepo.checkUsersExport,
		this._userRepo.getExportUsersFileLink
	);

	constructor() {
		this._users = new PaginationCollectionController<User, UserCollectionFilter>(
			20,
			async (limit, offset, filter) => {
				return await this._userRepo.getUserList(limit, offset, filter);
			}
		);

		makeAutoObservable(this);
	}

	// getters

	private get _userRepo() {
		return getUserRepo();
	}

	private get _currentUserService() {
		return getCurrentUserService();
	}

	get users() {
		return this._users;
	}

	get domainParticipants() {
		return this._domainParticipants;
	}

	get originators() {
		return this._originators;
	}

	get loadDomainParticipants() {
		return this._loadDomainParticipants;
	}

	// getters end
	// methods

	async sendInvitation(invitation: UserInvitation): Promise<number> {
		return await this._userRepo.sendUserInvitation(invitation);
	}

	async getDomainParticipants(
		domainCodes: Array<string>,
		domainRoles?: Array<string>,
		workingGroupRoles?: Array<string>
	) {
		this._loadDomainParticipants = true;
		const response = await this._userRepo.getDomainParticipants(
			domainCodes,
			domainRoles,
			workingGroupRoles
		);
		runInAction(() => {
			this._domainParticipants = response;
			this._loadDomainParticipants = false;
		});
		return response;
	}

	clearDomainParticipantsArray = () => {
		this._domainParticipants = '';
	};

	async getUserList(
		filter?: Partial<UserCollectionFilter>,
		limit: number = 20,
		offset: number = 0
	) {
		const response = await this._userRepo.getUserList(limit, offset, filter);

		runInAction(() => {
			this._originators = response.data;
		});

		return response;
	}

	getUser(id: number) {
		return this._userRepo.getUser(id);
	}

	updateUser(id: number, user: SingleUser) {
		return this._userRepo.updateUser(id, user);
	}

	resendInvitation(userId: number) {
		return this._userRepo.resendInvitation(userId);
	}

	async exportUsers(
		ids: IdsFilter,
		fileName: string,
		handleProgressUpdate: (nextProgress: number) => void,
		handleDownloaded: () => void,
		signal: AbortSignal
	) {
		this.usersExport.export(
			ids,
			this.users.filter,
			fileName,
			handleProgressUpdate,
			handleDownloaded,
			signal
		);
	}

	getExportFileName(title: string) {
		return `${title}_${moment().format('YYYY-MM-DD')}.xlsx`;
	}

	checkIfCurrentUserIsAbleToDeleteUser(user: UserInfo) {
		if (
			!this._currentUserService.currentUser ||
			this._currentUserService.currentUser.id === user.id
		) {
			return false;
		}
		if (this._currentUserService.hasRole(SystemRole.DIRECTOR)) {
			return true;
		}
		if (
			this._currentUserService.hasRole(SystemRole.ES) &&
			!user.fields.systemRoles.some(({ value: r }) =>
				[SystemRole.DIRECTOR, SystemRole.ES].includes(r as SystemRole)
			)
		) {
			return true;
		}
		return false;
	}

	deleteUser(id: number) {
		return this._userRepo.deleteUser(id);
	}

	// methods end
}
