import {
	CellContext,
	ColumnDef,
	ColumnSort,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { Tooltip } from 'react-tooltip';

import { getModalService } from '@asd-stan/shell/infrastructure/getters';
import { DownloadFile } from '@asd-stan/standard/components/download-file/download-file';
import { StandardChangeBlock } from '@asd-stan/standard/components/single-standard/tabs-sections/stage-and-status/standard-change-block/standard-change-block';
import { StandardTransactionHistoryListItem } from '@asd-stan/standard/domain/standard-stage.entity';
import {
	getStandardDetailsService,
	getStandardService,
} from '@asd-stan/standard/infrastructure/getters';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { ReactComponent as TableArrow } from '@assets/asd-stan-works/table/table-arrow.svg';
import { ReactComponent as EditHistory } from '@assets/icons/edit-history-icon.svg';
import { Loading } from '@components/loading/loading';
import { Flex } from '@components/utility/flex';
import { MirroredIcon } from '@components/utility/mirrored-icon';
import { DateTime } from 'luxon';

import { ReactComponent as EditIcon } from './assets/edit-icon.svg';
import { EditStageModal } from './edit-stage-modal';

import { AttachmentsLoader } from '@asd-stan/standard/components/single-standard/tabs-sections/attachments-section/attachments-section.styled';
import { StandardDisplayFieldValue } from '@asd-stan/standard/components/single-standard/tabs-sections/single-standard-page-section/single-standard.styled';
import {
	ActionButton,
	ActionButtonWrapper,
	StageAndStatusWrapper,
	StageTable,
	StyledTableBody,
	StyledTableHeader,
	StyledTableHeaderGroup,
	StyledTableHeaderPart,
	StyledTableRow,
	StyledTableRowCell,
	StyledTableRowWrapper,
	TableActions,
	TooltipStyles,
} from '@asd-stan/standard/components/single-standard/tabs-sections/stage-and-status/stage-and-status.styled';
import { StyledEmptyTable, StyledTablePagination } from '@components/content-table/table.styled';

interface Props {
	standardId: number;
}

const DATA_PER_PAGE = 10;

export const StageAndStatusSection: FC<Props> = observer(({ standardId }) => {
	const { t } = useTranslation();
	const standardService = getStandardService();
	const standardDetailedService = getStandardDetailsService();
	const [sorting, setSorting] = useState<ColumnSort[]>([]);
	const [pageCount, setPageCount] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [expandedRowIds, setExpandedRowIds] = useState<number[]>([]);
	const modalService = getModalService();

	const convertToDate = (dateString: string | null): string => {
		if (!dateString) {
			return t('standard.singleStandard.stageAndStatus.table.noDate');
		}

		return DateTime.fromISO(dateString).toFormat('dd LLL yyyy');
	};

	const onExpandTap = (id: number) => {
		setExpandedRowIds(prevState => {
			if (prevState.includes(id)) {
				return prevState.filter(prevId => prevId !== id);
			}

			return [...prevState, id];
		});
	};

	const openEditModal = (id: number) => {
		const { startDate, targetDate, completeDate } =
			standardService.standardTransactionHistory!.standardTransactionHistory.find(
				item => item.id === id
			)!;

		const values = {
			startDate: startDate ? new Date(startDate) : null,
			targetDate: targetDate ? new Date(targetDate) : null,
			completeDate: completeDate ? new Date(completeDate) : null,
		};

		modalService.openModal(
			<EditStageModal
				id={id}
				{...values}
				onSubmit={() =>
					standardService.getStandardTransactionHistory(
						standardId,
						DATA_PER_PAGE,
						currentPage * DATA_PER_PAGE
					)
				}
			/>
		);
	};

	useEffect(() => {
		standardService
			.getStandardTransactionHistory(standardId, DATA_PER_PAGE, currentPage * DATA_PER_PAGE)
			.then(() => {
				setPageCount(
					Math.ceil(
						standardService.standardTransactionHistory?.standardTransactionHistoryCount! /
							DATA_PER_PAGE
					)
				);
			});
	}, [standardId, standardService, currentPage, standardDetailedService.standard?.transaction]);

	const columns: ColumnDef<StandardTransactionHistoryListItem, string>[] = [
		{
			accessorKey: 'id',
		},
		{
			header: t('standard.singleStandard.stageAndStatus.table.stageCode'),
			accessorKey: 'code',
			cell: ({ row }: CellContext<StandardTransactionHistoryListItem, string>) => (
				<StandardDisplayFieldValue>{row.getValue<string>('code')}</StandardDisplayFieldValue>
			),
		},
		{
			header: t('standard.singleStandard.stageAndStatus.table.stage'),
			accessorKey: 'name',
			cell: ({ row }: CellContext<StandardTransactionHistoryListItem, string>) => (
				<StandardDisplayFieldValue title={row.getValue<string>('name')}>
					{row.getValue<string>('name')}
				</StandardDisplayFieldValue>
			),
		},
		{
			header: t('standard.singleStandard.stageAndStatus.table.startDate'),
			accessorKey: 'startDate',
			cell: ({ row }: CellContext<StandardTransactionHistoryListItem, string>) => (
				<StandardDisplayFieldValue className="grey-toned">
					{convertToDate(row.getValue<string>('startDate'))}
				</StandardDisplayFieldValue>
			),
		},
		{
			header: t('standard.singleStandard.stageAndStatus.table.targetDate'),
			accessorKey: 'targetDate',
			cell: ({ row }: CellContext<StandardTransactionHistoryListItem, string>) => (
				<StandardDisplayFieldValue className="grey-toned">
					{convertToDate(row.getValue<string>('targetDate'))}
				</StandardDisplayFieldValue>
			),
		},
		{
			header: t('standard.singleStandard.stageAndStatus.table.actualCompletion'),
			accessorKey: 'completeDate',
			cell: ({ row }: CellContext<StandardTransactionHistoryListItem, string>) => (
				<StandardDisplayFieldValue className="grey-toned">
					{convertToDate(row.getValue<string>('completeDate'))}
				</StandardDisplayFieldValue>
			),
		},
		{
			header: t('standard.singleStandard.stageAndStatus.table.actions'),
			accessorKey: 'latestDraft',
			cell: ({ row }: CellContext<StandardTransactionHistoryListItem, string>) => (
				<TableActions>
					<ActionButton
						data-tooltip-id="history-btn"
						className={`${expandedRowIds.includes(Number(row.id)) ? 'history-btn-active' : ''}`}
						onClick={() => onExpandTap(Number(row.id))}>
						<EditHistory />
					</ActionButton>
					{row.getValue<{ name: string; path: string }>('latestDraft') ? (
						<ActionButtonWrapper data-tooltip-id="download-btn">
							<DownloadFile
								hideFileIcon
								hideTitle
								notFromAssets
								title={row.getValue<{ name: string; path: string }>('latestDraft').name}
								file={row.getValue<{ name: string; path: string }>('latestDraft').path}
							/>
						</ActionButtonWrapper>
					) : null}
					<ActionButton
						data-tooltip-id="edit-btn"
						onClick={() => openEditModal(Number(row.getValue('id')))}>
						<EditIcon />
					</ActionButton>
					<Tooltip
						style={TooltipStyles}
						noArrow
						id="download-btn"
						place="top"
						offset={5}
						content={t('standard.singleStandard.stageAndStatus.table.tooltipDownload')}
					/>
					<Tooltip
						style={TooltipStyles}
						noArrow
						id="history-btn"
						place="top"
						offset={5}
						content={t('standard.singleStandard.stageAndStatus.table.tooltipHistory')}
					/>
					<Tooltip
						style={TooltipStyles}
						noArrow
						id="edit-btn"
						place="top"
						offset={5}
						content={t('standard.singleStandard.stageAndStatus.editModal.tooltip')}
					/>
				</TableActions>
			),
		},
	];

	const table = useReactTable<StandardTransactionHistoryListItem>({
		data: standardService.standardTransactionHistory
			? standardService.standardTransactionHistory.standardTransactionHistory
			: [],
		initialState: {
			columnVisibility: {
				id: false,
			},
		},
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		state: {
			sorting: sorting,
		},
		columns: columns,
		onSortingChange: prevState => setSorting(prevState),
	});

	if (standardService.dataLoading) {
		return (
			<AttachmentsLoader>
				<Flex $align="center" $justify="center" $direction="column">
					<LogoIcon />
					<Loading horizontal />
				</Flex>
			</AttachmentsLoader>
		);
	}

	const handleGoToPage = (pageNumber: number) => {
		return setCurrentPage(pageNumber);
	};

	return (
		<StageAndStatusWrapper>
			{table.getFilteredRowModel().rows.length > 0 ? (
				<>
					<StageTable
						style={{ outline: '1px solid #DAE4E8', border: 'none', position: 'relative' }}>
						<StyledTableHeader>
							{table.getHeaderGroups().map(headerGroup => (
								<StyledTableHeaderGroup key={headerGroup.id}>
									{headerGroup.headers.map((header, index) => (
										<StyledTableHeaderPart
											style={{ flex: header.id.includes('name') ? 2 : 1 }}
											key={header.id}
											onClick={header.column.getToggleSortingHandler()}>
											{header.isPlaceholder ? null : (
												<div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
											)}
										</StyledTableHeaderPart>
									))}
								</StyledTableHeaderGroup>
							))}
						</StyledTableHeader>
						<StyledTableBody>
							{table.getRowModel().rows.map(row => (
								<StyledTableRowWrapper key={row.id}>
									<StyledTableRow>
										{row.getVisibleCells().map(cell => (
											<StyledTableRowCell
												style={{ flex: cell.id.includes('name') ? 2 : 1 }}
												key={cell.id}>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</StyledTableRowCell>
										))}
									</StyledTableRow>
									{expandedRowIds.includes(Number(row.id)) ? (
										<StandardChangeBlock
											standardId={standardId}
											transactionId={row.getValue('id')}
										/>
									) : null}
								</StyledTableRowWrapper>
							))}
						</StyledTableBody>
					</StageTable>
					{pageCount !== 1 ? (
						<StyledTablePagination>
							<ReactPaginate
								disabledClassName="disabled"
								pageCount={pageCount}
								pageRangeDisplayed={3}
								marginPagesDisplayed={1}
								previousLabel={
									<MirroredIcon>
										<TableArrow />
									</MirroredIcon>
								}
								nextLabel={<TableArrow />}
								onPageChange={({ selected }) => handleGoToPage(selected)}
								containerClassName="pagination"
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakClassName="page-item break-button"
								breakLinkClassName="page-link"
								activeClassName="active"
								forcePage={currentPage}
							/>
						</StyledTablePagination>
					) : null}
				</>
			) : (
				<StyledEmptyTable />
			)}
		</StageAndStatusWrapper>
	);
});
