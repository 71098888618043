import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { DraftDetailed } from '@asd-stan/draft/domain/draft-detailed.entity';
import { DraftUpdate } from '@asd-stan/draft/domain/draft-update';
import { getDraftService } from '@asd-stan/draft/infrastructure/getters';
import { userHasRoles } from '@asd-stan/helpers/app-utils';
import { ExportController } from '@asd-stan/helpers/export-controller';
import { PaginationCollectionController } from '@asd-stan/helpers/pagination-collection-controller';
import { Remark } from '@asd-stan/standard/domain/remark.entity';
import {
	StandardStage,
	StandardTransactionHistory,
} from '@asd-stan/standard/domain/standard-stage.entity';
import { Tag } from '@asd-stan/standard/domain/tag.entity';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { User } from '@asd-stan/user/domain/user.entity';
import { IdsFilter, allRows } from '@components/content-table/content-table';
import { makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment';

import { getStandardRepo } from '../infrastructure/getters';

import { Classification } from './classification.entity';
import { Form } from './enums';
import { IcsCode } from './ics-code.entity';
import { EditStageForm } from './stage-update.entity';
import {
	AttachmentResponse,
	AttachmentUpload,
	StandardDetailed,
	StandardDetailedRemark,
} from './standard-detailed.entity';
import { Attachment, StandardUpdate } from './standard-update';
import { Standard } from './standard.entity';
import { Type } from './type.entitty';

export interface StandardCollectionFilter {
	domain?: string | null;
	workingGroup?: string | null;
	isDraft?: boolean;
	isAssigned?: boolean;
	orderField?: string;
	orderDirection?: string;
	search?: string | null;
	forms?: null | Record<Form, boolean>;
	stages?: null | Record<string, boolean>;
	ids?: null | number[];
	myDashboard?: boolean;
}

export class StandardService {
	private _allTypes: Array<Type> = [];
	private _singleStandard: StandardDetailed | null = null;
	singleStandardNotFound: boolean = false;
	private _standards: PaginationCollectionController<Standard, StandardCollectionFilter>;
	private _allocationCount: number = 0;
	private _latestStandards: Standard[] = [];
	private _remarks: Array<Remark> = [];
	private _standardList: Array<Standard> = [];
	private _originatorList: Array<User> = [];
	private _tags?: Tag[] = [];
	private _classifications?: Classification[] = [];
	private _icsCodes?: IcsCode[] = [];
	private _attachments?: AttachmentResponse[] | null = null;
	private _standardTransactionHistory: StandardTransactionHistory | null = null;
	private _dataLoading: boolean = false;
	private _attachmentIds: { id: number; file: { id: number } }[] = [];
	private _stagesList: StandardStage[] = [];
	standardsExport = new ExportController(
		this._standardRepo.initStandardsExport,
		this._standardRepo.cancelStandardsExport,
		this._standardRepo.checkStandardsExport,
		this._standardRepo.getExportStandardsFileLink
	);

	constructor() {
		this._standards = new PaginationCollectionController<Standard, StandardCollectionFilter>(
			20,
			async (limit, offset, filter) => {
				this._singleStandard = null;
				return await this._standardRepo.getStandards(limit, offset, filter);
			},
			{
				domain: null,
				workingGroup: null,
				orderField: 'id',
				orderDirection: 'DESC',
				search: null,
			}
		);

		makeAutoObservable(this);
	}

	//getters

	private get _standardRepo() {
		return getStandardRepo();
	}

	private get _draftService() {
		return getDraftService();
	}

	private get _currentUserService() {
		return getCurrentUserService();
	}

	get dataLoading() {
		return this._dataLoading;
	}

	get stagesList() {
		return this._stagesList;
	}

	get allTypes() {
		return this._allTypes;
	}

	get standards() {
		return this._standards;
	}

	get allocationCount() {
		return this._allocationCount;
	}

	get latestStandards() {
		return this._latestStandards;
	}

	get standardList() {
		return this._standardList;
	}

	get remarks() {
		return this._remarks;
	}

	get tags() {
		return this._tags;
	}

	get classifications() {
		return this._classifications;
	}

	get icsCodes() {
		return this._icsCodes;
	}

	get originatorList() {
		return this._originatorList;
	}

	get singleStandard() {
		return this._singleStandard;
	}

	get attachments() {
		return this._attachments;
	}

	get standardTransactionHistory() {
		return this._standardTransactionHistory;
	}

	get attachmentIds() {
		return this._attachmentIds;
	}

	//getters end
	//methods

	public async getAllTypes() {
		const allTypes = await this._standardRepo.getAllStandardTypes();

		runInAction(() => {
			this._allTypes = allTypes;
		});
	}

	async getAllocationCount() {
		const standardCount = await this._standardRepo.getStandardAllocationCount();

		runInAction(() => {
			this._allocationCount = standardCount;
		});
	}

	async getNextRegNumber(form: string): Promise<string> {
		return await this._standardRepo.getNextRegNumber(form);
	}

	async getNextStanNumber(form: string): Promise<string> {
		return await this._standardRepo.getNextStanNumber(form);
	}

	async getStandardById(id: number) {
		const response = await this._standardRepo.getStandardById(id);

		runInAction(() => {
			if (typeof response === 'string') {
				this.singleStandardNotFound = true;
			} else {
				this._singleStandard = response;
			}
		});
	}

	async getLatestStandardsList(limit: number = 6, offset: number = 0, filter: any) {
		const response = await this._standardRepo.getStandardList(limit, offset, filter);
		runInAction(() => {
			this._latestStandards = response.standardList;
		});
		return response.standardCount;
	}

	async addRemarkToStandard(standardId: number, text: string) {
		try {
			const response = await this._standardRepo.addRemarkToStandard(standardId, text);

			runInAction(() => {
				if (!this._singleStandard?.remarks || !this._singleStandard.remarks.length) {
					this._singleStandard!.remarks = [response];
				} else {
					this._singleStandard!.remarks = [...this._singleStandard!.remarks, response];
				}
			});
		} catch (error) {
			console.error(error);
		}
	}

	//TODO: Fix it when we gonna refactor it and add pagination controller

	// async getAllAdditionalInformation() {
	// 	const response = await this._standardRepo.getAllAdditionalInformation(6);
	//
	// 	runInAction(() => {
	// 		//@ts-ignore
	// 		this._tags = response.tags;
	// 		//@ts-ignore
	// 		this._classifications = response.classifications;
	// 		//@ts-ignore
	// 		this._icsCodes = response.icsCodes;
	// 	});
	// }

	async getTags(limit: number = 6, offset: number = 0, inputValue: string) {
		const response = await this._standardRepo.getTags(limit, offset, inputValue);

		runInAction(() => {
			this._tags = response.data.map((tag: Tag) => new Tag({ value: tag.value }));
		});
		return response.totalNumber;
	}

	async getClassifications(limit: number = 6, offset: number = 0, inputValue: string) {
		const response = await this._standardRepo.getClassifications(limit, offset, inputValue);
		runInAction(() => {
			this._classifications = response.classifications;
		});
		return response.classificationsCount;
	}

	async getIcsCodes(limit: number = 6, offset: number = 0, inputValue: string) {
		const response = await this._standardRepo.getIcsCodes(limit, offset, inputValue);

		runInAction(() => {
			this._icsCodes = response.icsCodes;
		});
		return response.icsCodesCount;
	}

	async addRemark({ name, text, date }: Remark) {
		this._remarks.push({ name: name, text: text, date: date, stageUpdate: null });
	}

	async updateStandard(creation: StandardUpdate) {
		return await this._standardRepo.updateStandard(creation);
	}

	removeRemark(index: number) {
		if (this._singleStandard !== null) {
			this._singleStandard.remarks.splice(index, 1);
		}
		this._remarks.splice(index, 1);
	}

	async getStandardList(
		limit: number = 20,
		offset: number = 0,
		needClearSingleStandard: boolean = true
	) {
		const response = await this._standardRepo.getStandardList(limit, offset, {
			isDraft: false,
			orderField: 'title',
			orderDirection: 'DESC',
		});

		runInAction(() => {
			this._standardList = response.standardList;
			if (needClearSingleStandard) {
				this._singleStandard = null;
			}
		});

		return response.standardCount as number;
	}

	getDashboardStandards(limit: number, offset: number, filter: StandardCollectionFilter) {
		return this._standardRepo.getStandards(limit, offset, {
			...filter,
			myDashboard: true,
		});
	}

	async getOriginatorList(filter?: Partial<any>, limit: number = 6, offset: number = 0) {
		const response = await this._standardRepo.getOriginatorList(
			limit,
			offset,
			filter
				? filter
				: {
						firstName: '',
						lastName: '',
						phone: '',
						email: '',
						company: '',
				  }
		);

		runInAction(() => {
			this._originatorList = response;
		});
	}

	async getAttachmentsByStandardId(id: number) {
		const response = await this._standardRepo.getAttachmentsByStandardId(id);

		runInAction(() => {
			this._attachments = response;
		});
	}

	async addAttachmentToStandard(attachments: AttachmentUpload[]) {
		try {
			const attachmentsToUpload = attachments.filter(attachment => attachment.file.fileId);

			for (const attachment of attachmentsToUpload) {
				await this._standardRepo.addAttachmentToStandard(this._singleStandard!.id, attachment);
			}

			await this.getAttachmentsByStandardId(this._singleStandard!.id);
		} catch (error) {
			console.error(error);
		}
	}

	async deleteAttachmentFromStandard(id: number) {
		try {
			await this._standardRepo.deleteAttachmentFromStandard(id);
			await this.getAttachmentsByStandardId(this._singleStandard!.id);
		} catch (error) {
			console.error(error);
		}
	}

	async getStandardTransactionHistory(id: number, limit: number, offset: number) {
		try {
			runInAction(() => {
				this._dataLoading = true;
			});
			const response = await this._standardRepo.getTransactionHistory(id, limit, offset);

			runInAction(() => {
				this._standardTransactionHistory = response;
				this._dataLoading = false;
			});
		} catch (err) {
			console.error(err);
		}
	}

	async getStandardStageUpdateChangeHistory(
		standardId: number,
		transactionId: number,
		limit: number,
		offset: number
	) {
		try {
			return await this._standardRepo.getStageUpdateHistory(
				standardId,
				transactionId,
				limit,
				offset
			);
		} catch (err) {
			console.error(err);
		}
	}

	clearSingleStandard() {
		this._singleStandard = null;
	}

	updateRemarks(remarks: Array<StandardDetailedRemark>) {
		this._remarks = remarks.map(remark => ({
			name: `${remark.createdBy?.firstName} ${remark.createdBy?.lastName}`,
			text: remark.text,
			date: remark.createdAt!,
			stageUpdate: remark.stageUpdate,
		}));
	}

	async updateDraft(draftId: number, update: DraftUpdate) {
		const data = await this._standardRepo.updateDraft(draftId, update);

		runInAction(() => {
			this._attachmentIds = data.attachments;
		});
	}

	async moveDraftToNWP(draftId: number) {
		this._remarks = [];
		this._draftService.remarks = null;
		return await this._standardRepo.moveDraftToNWP(draftId);
	}

	makeDraftCreation(
		draft: DraftDetailed,
		updatedAttachments: Attachment[] | null,
		updatedRemarks: Array<{ text: string }> | null
	) {
		return {
			generalData: {
				form: draft.generalData?.form ?? null,
				registrationNumber: draft.generalData?.registrationNumber ?? '',
				stanNumber: draft.generalData?.stanNumber ?? '',
				isRevision: draft.generalData?.isRevision ?? null,
				revisionId: draft.generalData?.revision?.id ?? null,
				edition: draft.generalData?.edition ?? '',
				languages: draft.generalData?.languages ?? [],
				typeIds: draft.generalData?.standardTypes.map(({ id }) => id) ?? [],
				pages: draft.generalData?.pages ?? null,
				domainCodes: draft.generalData?.domains?.map(domain => domain.code) ?? [],
				isWorkingGroupNull: draft.generalData?.isWorkingGroupNull ?? false,
				workingGroupCodes: draft.generalData?.workingGroups?.map(group => group.code) ?? [],
				leaderId: draft.generalData?.leader?.id ?? null,
				scope: draft.generalData?.scope ?? '',
				cenWiNumber: draft.generalData?.cenWiNumber ?? null,
			},
			titles: {
				en: draft.titles && draft.titles.en,
				de: draft.titles && draft.titles.de,
				fr: draft.titles && draft.titles.fr,
			},
			draft: draft.draft ? draft.draft.id : null,
			attachments:
				updatedAttachments === null
					? draft.attachments
						? draft.attachments.map(attachment => ({
								fileId: attachment.file.id,
								type: attachment.type,
								description: attachment.description,
						  }))
						: []
					: updatedAttachments,
			justification: {
				purpose: draft.justification && draft.justification.purpose,
				revisionJustification: draft.justification && draft.justification.revisionJustification,
				technicalChanges:
					draft.justification &&
					draft.justification.technicalChanges.map(change => ({
						id: change.id,
						title: change.title,
						change: change.change,
					})),
				consideredStandards:
					draft.justification &&
					draft.justification.consideredStandards.map(standard => standard.id),
				externalConsideredStandards:
					draft.justification && draft.justification.externalConsideredStandards,
				knownPatentIssue: draft.justification && draft.justification.knownPatentIssue,
				patentReferences: draft.justification && draft.justification.patentReferences,
				patentFile: draft.justification?.patentFile?.id ?? null,
			},
			originator: {
				isAsdOriginator: draft.originator?.isAsdOriginator ?? false,
				isExistOriginator: draft.originator?.isExistOriginator ?? false,
				userId: Number(draft.originator?.userId!),
				firstName: draft.originator?.firstName,
				lastName: draft.originator?.lastName,
				email: draft.originator?.email,
				phone: draft.originator?.phone,
				companyId: draft.originator?.company?.id,
				countryId: draft.originator?.country?.id,
				positions: draft.originator?.positions.map(position => position.id),
			},
			experts: {
				experts: draft.experts?.experts.map(expert => expert.id) ?? [],
				wgs: draft.experts?.wgs?.id ?? null,
			},
			enData: {
				name: draft.enData.name,
				publicationDate: moment(draft.enData.publicationDate).format('YYYY-MM'),
				title: draft.enData.title,
			},
			remarks:
				updatedRemarks === null
					? draft.remarks
						? draft.remarks.map((remark: { text: string }) => ({
								text: remark.text,
						  }))
						: []
					: updatedRemarks,
			additionalInformation: {
				tags: draft.additionalInformation?.tags?.map(tag => tag.value) ?? [],
				classifications:
					draft.additionalInformation?.classifications?.map(classification => classification.id) ??
					[],
				icsCodes: draft.additionalInformation?.icsCodes?.map(code => code.code) ?? [],
			},
		};
	}

	async deleteAttachmentFromDraft(draft: DraftDetailed | null, id: number) {
		if (!draft || !draft.attachments || !Array.isArray(draft.attachments)) {
			return;
		}

		const filteredAttachments = draft.attachments.filter(attachment => attachment.file.id !== id);
		const updatedAttachments = filteredAttachments.map(attachment => ({
			fileId: attachment.file.id,
			description: attachment.description,
			type: attachment.type,
		}));

		const data = await this._standardRepo.updateDraft(
			draft.id,
			this.makeDraftCreation(draft, updatedAttachments, null)
		);

		runInAction(() => {
			this._attachmentIds = data.attachments;
		});
	}

	async addAttachmentToDraft(attachments: AttachmentUpload[], draft: DraftDetailed | null) {
		if (!draft) {
			return;
		}

		try {
			const attachmentsToUpload = attachments
				.filter(attachment => attachment.file.fileId)
				.map(attachment => ({
					fileId: attachment.file.fileId,
					description: attachment.attachmentDescription,
					type: attachment.attachmentType.value,
				}));

			const updatedAttachments = draft.attachments
				? draft.attachments.map(attachment => ({
						fileId: attachment.file.id,
						description: attachment.description,
						type: attachment.type,
				  }))
				: [];

			const data = await this._standardRepo.updateDraft(
				draft.id,
				this.makeDraftCreation(draft, [...attachmentsToUpload, ...updatedAttachments], null)
			);

			runInAction(() => {
				this._attachmentIds = data.attachments;
			});
		} catch (error) {
			console.error(error);
		}
	}

	async getPrivateAttachmentLinkById(id: number): Promise<string> {
		return await this._standardRepo.getPrivateAttachmentLinkById(id);
	}

	async getPrivateStandardDraftLinkById(id: number): Promise<string> {
		return await this._standardRepo.getPrivateStandardDraftLinkById(id);
	}

	async getPrivateDraftLinkById(id: number, index: number): Promise<string> {
		return await this._standardRepo.getPrivateDraftLinkById(id, index);
	}

	async exportStandards(
		ids: IdsFilter,
		fileName: string,
		handleProgressUpdate: (nextProgress: number) => void,
		handleDownloaded: () => void,
		signal: AbortSignal
	) {
		this.standardsExport.export(
			ids,
			this.standards.filter,
			fileName,
			handleProgressUpdate,
			handleDownloaded,
			signal
		);
	}

	getExportFileName(title: string) {
		return `${title}_${moment().format('YYYY-MM-DD')}.xlsx`;
	}

	checkIfCurrentUserCanEditRegAndStanNo(domainCodes: string[]) {
		return (
			userHasRoles([SystemRole.ES, SystemRole.DIRECTOR], this._currentUserService.userRoles!) ||
			this._currentUserService.hasWGSRoleInDomains(domainCodes)
		);
  }

	editStage(id: number, editStageForm: EditStageForm) {
		return this._standardRepo.editStage(id, editStageForm);
	}

	//methods end
}
