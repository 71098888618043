import moment from 'moment';

import { EditStageForm } from '../domain/stage-update.entity';
import {
	StandardTransactionHistoryListItem,
	StandardTransactionHistoryListItemDto,
} from '../domain/standard-stage.entity';

export const mapTransactionHistoryDto = (
	item: StandardTransactionHistoryListItemDto
): StandardTransactionHistoryListItem => ({
	id: item.id,
	code: item.stages.map(({ code }) => code).join(', '),
	name: item.stages.map(({ name }) => name).join(', '),
	completeDate: item.completeDate,
	startDate: item.startDate,
	targetDate: item.targetDate,
});

export const mapEditStage = ({ completeDate, startDate, targetDate }: EditStageForm) => ({
	startDate: moment(startDate).format('YYYY-MM-DD'),
	targetDate: targetDate ? moment(targetDate).format('YYYY-MM-DD') : null,
	completeDate: completeDate ? moment(completeDate).format('YYYY-MM-DD') : null,
});
