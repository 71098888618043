import { styled } from 'styled-components';

export const OriginatorHeader = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: center;
	gap: 16px;
`;

export const OriginatorStatus = styled.div`
	color: #525259;
	font-size: 13px;
	font-weight: 500;
	background-color: #dae4e8;
	border-radius: 8px;
	padding: 4px 8px 4px 8px;
`;

export const OriginatorInfo = styled.div<{ deleted: boolean }>`
	display: flex;
	flex-direction: column;
	gap: 16px;

	& .name,
	& .email {
		opacity: ${({ deleted }) => (deleted ? 0.6 : 1)};
	}

	& .block {
		display: flex;
		gap: 4px;
	}
`;

export const OriginatorContainer = styled.div`
	margin-top: 16px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	align-items: center;
`;

export const OriginatorWrapper = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
`;

export const OriginatorEmail = styled.div`
	font-size: 13px;
	font-weight: 600;
	color: #abb3bb;
`;

export const OriginatorName = styled.div`
	font-size: 20px;
	color: #333336;
	font-weight: 600;
	gap: 8px;
`;

export const OriginatorViewLinksIcon = styled.div`
	width: 18px;
	height: 18px;
`;

export const OriginatorDeleted = styled.div`
	color: #525259;
	font-size: 13px;
	background-color: #dae4e8;
	border-radius: 8px;
	padding: 4px 8px 4px 8px;
	font-style: italic;
`;
