import { UserPublic } from '@asd-stan/user/public-contract/user.public-entity';
import { makeAutoObservable } from 'mobx';

export class UserPublicService {
	private _selectedUser: null | UserPublic = null;

	constructor() {
		makeAutoObservable(this);
	}

	// getters

	get selectedUser() {
		return this._selectedUser;
	}

	// getters end
	// methods

	// methods end
}
