import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, createSearchParams, useNavigate } from 'react-router-dom';

import { signInRedirectParamKey } from '@asd-stan/auth/domain/constants';
import { getAuthService } from '@asd-stan/auth/infrastructure/getters';
import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { AccessibleDomain } from '@asd-stan/domain/domain/domain-access.service';
import { getDomainAccessService, getDomainService } from '@asd-stan/domain/infrastructure/getters';
import { getDraftService } from '@asd-stan/draft/infrastructure/getters';
import { Header } from '@asd-stan/shell/components/header/header';
import { Modal } from '@asd-stan/shell/components/modal/modal-root';
import { Sidebar } from '@asd-stan/shell/components/sidebar/sidebar';
import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { Toaster } from '@asd-stan/ui-kit/components/toaster/toaster';
import { Loading } from '@components/loading/loading';
import { ScrollToTop } from '@components/scroll-to-top/scroll-to-top';
import { Flex } from '@components/utility/flex';

import { StyledMainLayout } from './main-layout.styled';

import { StyledRouteContainer } from '@asd-stan/router/route-container.styled';

export const MainLayout: React.FC = observer(() => {
	const navigate = useNavigate();

	const currentUserService = getCurrentUserService();
	const draftService = getDraftService();
	const standardService = getStandardService();
	const domainService = getDomainService();
	const domainAccessService = getDomainAccessService();
	const authService = getAuthService();
	const [domains, setDomains] = useState<AccessibleDomain[]>([]);

	const { accessToken }: Storage = localStorage;

	useEffect(() => {
		const onLoadPage = async () => {
			try {
				await currentUserService.getMe();
			} catch (error) {
				console.error(error);
			}
		};

		onLoadPage();
	}, [authService, navigate, currentUserService]);

	const getDomains = async () => {
		domainAccessService.setLoading(true);
		await domainService.getDomains();
		await domainAccessService.createDomainMap();

		if (currentUserService.currentUser) {
			await standardService.getAllocationCount();
			await draftService.getDraftsCount();
		}

		domainAccessService.setLoading(false);

		setDomains([...domainAccessService.accessibleDomains]);
	};

	useEffect(() => {
		getDomains();
	}, []);

	useEffect(() => {
		const updateCounters = async () => {
			await standardService.getAllocationCount();
			await draftService.getDraftsCount();
		};

		updateCounters();
	}, [standardService, standardService.standards.totalNumber]);

	if (!accessToken) {
		return (
			<Navigate
				to={`/signin?${createSearchParams({ [signInRedirectParamKey]: window.location.pathname })}`}
			/>
		);
	}

	if (!currentUserService?.currentUser) {
		return (
			<StyledRouteContainer $fullWidth>
				<Flex $align="center" $justify="center" $direction="column">
					<LogoIcon />
					<Loading horizontal />
				</Flex>
			</StyledRouteContainer>
		);
	}

	return (
		<StyledMainLayout>
			<Modal />
			<Toaster />
			<Header currentUser={currentUserService.currentUser} />
			<Outlet />
			<Sidebar domains={[...domains]} loadingDomains={domainAccessService.isLoading} />
			<ScrollToTop />
		</StyledMainLayout>
	);
});
