import { gql } from '@apollo/client';
import { stanWorksClient } from '@asd-stan/config/api';

import { MeetingListFilter, ValidatedMeetingForm } from '../domain/meeting.entity';

import { mapMeetingList, mapMeetingListFilter } from './meeting-list.mapper';
import { mapMeetingDto, mapSingleMeeting } from './meeting.mapper';

const CREATE_MEETING = gql`
	mutation ($meeting: MeetingInput!) {
		createMeeting(meeting: $meeting) {
			id
		}
	}
`;

const GET_SINGLE_MEETING = gql`
	query ($id: Int!) {
		meeting(id: $id) {
			domains {
				code
				name
			}
			workingGroups {
				code
				name
			}
			title
			type
			startTime
			endTime
			link
			location
			standard {
				id
				registrationNumber
				form
				edition
				title
			}
			attachments {
				id
				file {
					id
					path
					name
					size
				}
				createdAt
				createdBy {
					firstName
					lastName
				}
				description
			}
			createdBy {
				id
			}
		}
	}
`;

const GET_FILE_DOWNLOAD_URL = gql`
	query ($id: Int!) {
		meetingAttachmentFile(id: $id)
	}
`;

const UPDATE_MEETING = gql`
	mutation ($id: Int!, $meeting: MeetingInput!) {
		updateMeeting(id: $id, meeting: $meeting) {
			id
		}
	}
`;

const DELETE_MEETING = gql`
	mutation ($id: Int!) {
		deleteMeeting(id: $id)
	}
`;

const MEETING_LIST = gql`
	query ($limit: Int!, $offset: Int!, $filter: MeetingFilterInput) {
		meetingList(limit: $limit, offset: $offset, filter: $filter) {
			id
			title
			type
			domains {
				code
				name
			}
			workingGroups {
				code
				name
			}
			startTime
			endTime
			location
		}
		meetingCount(filter: $filter)
	}
`;

export class MeetingRepo {
	async createMeeting(meetingValues: ValidatedMeetingForm) {
		const meeting = mapMeetingDto(meetingValues);
		await stanWorksClient.mutate({
			mutation: CREATE_MEETING,
			variables: {
				meeting,
			},
		});
	}

	async getSingleMeeting(id: number) {
		const { data } = await stanWorksClient.query({
			query: GET_SINGLE_MEETING,
			variables: {
				id,
			},
		});
		return mapSingleMeeting(data.meeting);
	}

	async getFileDownloadUrl(fileId: number) {
		const { data } = await stanWorksClient.query({
			query: GET_FILE_DOWNLOAD_URL,
			variables: {
				id: fileId,
			},
		});
		return data.meetingAttachmentFile as string;
	}

	async updateMeeting(id: number, meetingValues: ValidatedMeetingForm) {
		const meeting = mapMeetingDto(meetingValues);
		await stanWorksClient.mutate({
			mutation: UPDATE_MEETING,
			variables: {
				id,
				meeting,
			},
		});
	}

	async deleteMeeting(id: number) {
		await stanWorksClient.mutate({
			mutation: DELETE_MEETING,
			variables: {
				id,
			},
		});
	}

	async getMeetingList(limit: number, offset: number, filter: MeetingListFilter) {
		const { data } = await stanWorksClient.query({
			query: MEETING_LIST,
			variables: {
				limit,
				offset,
				filter: mapMeetingListFilter(filter),
			},
		});
		return mapMeetingList(data);
	}
}
