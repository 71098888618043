import { makeCompanyFromCompanyDTO } from '@asd-stan/user/api/company/company.factory';
import { makeCountryFromCountryDTO } from '@asd-stan/user/api/country/country.factory';
import { UserInvitationDTO } from '@asd-stan/user/api/invite-user.dto';
import { mapSystemRoleDTOToSystemRole } from '@asd-stan/user/api/system-role.mapper';
import { UserDTO } from '@asd-stan/user/api/user.dto';
import { UserInvitation } from '@asd-stan/user/domain/user-invitation';
import { User } from '@asd-stan/user/domain/user.entity';
import { UserPublic } from '@asd-stan/user/public-contract/user.public-entity';
import { DateTime } from 'luxon';

export const makeUserFromUserDTO = (dto: UserDTO): User => {
	return new User({
		id: dto.id,
		userId: dto.userId,
		firstName: dto.firstName,
		lastName: dto.lastName,
		phone: dto.phone,
		email: dto.email,
		available: dto.available,
		appointerId: dto.appointerId,
		appointer: dto.appointer ? makeUserFromUserDTO(dto.appointer) : null,
		invitedById: dto.invitedById,
		invitedBy: dto.invitedBy ? makeUserFromUserDTO(dto.invitedBy) : null,
		country: dto.country && makeCountryFromCountryDTO(dto.country),
		company: dto.company && makeCompanyFromCompanyDTO(dto.company),
		systemRoles: mapSystemRoleDTOToSystemRole(dto.systemRoles),
		createdAt: DateTime.fromISO(dto.createdAt),
		updatedAt: DateTime.fromISO(dto.updatedAt),
		positions: dto.positions,
		isAppointerLoaded: !!dto.appointer,
		isInvitedByLoaded: !!dto.invitedBy,
		deletedAt: dto.deletedAt,
	});
};

export const makeUserPublicFromUser = (user: User): UserPublic => {
	return new UserPublic({
		user,
	});
};

export const makeUserInvitationDTO = (invitation: UserInvitation): UserInvitationDTO => {
	return {
		user: {
			firstName: invitation.firstName,
			lastName: invitation.lastName,
			email: invitation.email,
			phone: invitation.phone,
			appointedById: invitation.appointerId,
			systemRoles: invitation.systemRoles,
			companyId: invitation.companyId,
			countryId: invitation.companyCountryId,
			positionIds: invitation.positionIds,
		},
		domainParticipations: invitation.domainParticipations,
		workingGroupParticipations: invitation.workingGroupParticipations,
	};
};
