import styled from 'styled-components';

export const StyledBallotList = styled.div`
	margin-top: 24px;

	.date {
		font-weight: 400;
		color: #abb3bb;
	}

	.titleCell {
		white-space: pre-wrap;
		display: -webkit-box;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
	}
`;

export enum ChipType {
	Success = 'success',
	Warning = 'warning',
	Error = 'errror',
	Default = 'default',
}

const chipColors: Record<ChipType, { color: string; 'background-color': string }> = {
	[ChipType.Success]: { color: '#09B910', 'background-color': '#09B91014' },
	[ChipType.Warning]: { color: '#E3A70B', 'background-color': '#E3A70B14' },
	[ChipType.Error]: { color: '#EA3E53', 'background-color': '#EA3E5314' },
	[ChipType.Default]: { color: '#525259', 'background-color': '#52525914' },
};

export const Chip = styled.div<{ $type: ChipType }>`
	${({ $type }) => chipColors[$type]};
	border-radius: 8px;
	margin-right: 8px;
	padding: 4px 6px;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	width: fit-content;
	text-transform: capitalize;
`;
