import { gql } from '@apollo/client';
import { stanWorksClient } from '@asd-stan/config/api';
import { DraftUpdate } from '@asd-stan/draft/domain/draft-update';
import { ExportStatus } from '@asd-stan/helpers/export-controller';
import { Page } from '@asd-stan/helpers/pagination-collection-controller';
import {
	makeStandardStageFromArgs,
	mapStageHistoryChanges,
} from '@asd-stan/standard/api/standard-stage.factory';
import {
	StandardStage,
	StandardStageArgs,
	StandardStageUpdateHistoryResponse,
	StandardStageUpdateHistoryResponseParsed,
	StandardTransactionHistoryResponse,
} from '@asd-stan/standard/domain/standard-stage.entity';
import { StandardCollectionFilter } from '@asd-stan/standard/domain/standard.service';

import { PriceHistory, PriceHistoryItem } from '../domain/price-history.entity';
import { EditStageForm, UpdateStageForm } from '../domain/stage-update.entity';
import {
	AttachmentResponse,
	AttachmentUpload,
	StandardDetailed,
	StandardDetailedRemark,
} from '../domain/standard-detailed.entity';
import { StandardUpdate } from '../domain/standard-update';
import { Standard } from '../domain/standard.entity';
import { Type } from '../domain/type.entitty';

import { mapStandardListFilter } from './standard-list.mapper';
import { StandardDTO, StandardListDTO } from './standard.dto';
import {
	makeStandardDetailedFromStandardDetailedDTO,
	makeStandardFromStandardDTO,
} from './standard.factory';
import { mapEditStage, mapTransactionHistoryDto } from './transaction-history-dto.mapper';
import { mapUpdateStageDto } from './update-stage.mapper';

const NEXT_REG_NUMBER = gql`
	query ($form: String!) {
		nextRegNumber(form: $form)
	}
`;

const NEXT_STAN_NUMBER = gql`
	query ($form: String!) {
		nextStanNumber(form: $form)
	}
`;

const ALL_STANDARD_TYPES = gql`
	query {
		allStandardTypes {
			id
			name
		}
	}
`;

const LATEST_STANDARDS = gql`
	query ($limit: Int!, $offset: Int!, $registrationNumber: String!) {
		latestStandards(limit: $limit, offset: $offset, registrationNumber: $registrationNumber) {
			id
			registrationNumber
			form
			edition
			localizedTitle
		}
	}
`;

const STANDARD_LIST = gql`
	query ($limit: Int!, $offset: Int!, $filter: StandardFilterInput) {
		standardList(limit: $limit, offset: $offset, filter: $filter) {
			id
			stanNumber
			registrationNumber
			form
			edition
			cenWiNumber
			localizedTitle
			statuses
			originator {
				userId
			}
		}
		standardCount(filter: $filter)
	}
`;

const STANDARD_COUNTS = gql`
	query ($filter: StandardFilterInput) {
		standardCount(filter: $filter)
	}
`;

const ORIGINATOR_LIST = gql`
	query ($limit: Int!, $offset: Int!, $filter: OriginatorFilterInput) {
		originatorList(limit: $limit, offset: $offset, filter: $filter) {
			id
			userId
			firstName
			lastName
			email
			phone
			company {
				id
				name
			}
			country {
				id
				name
			}
			positions {
				id
				name
			}
		}
	}
`;

const UPDATE_STANDARD = gql`
	mutation (
		$id: Int!
		$generalData: StandardGeneralInput!
		$titles: StandardTitlesInput!
		$draft: Int
		$justification: StandardJustificationInput!
		$originator: StandardOriginatorInput!
		$experts: StandardExpertsInput!
		$icsCodes: [String!]
		$tags: [String!]
		$classifications: [Int!]
		$enData: TransformationDataInput
	) {
		updateStandard(
			id: $id
			generalData: $generalData
			titles: $titles
			draft: $draft
			justification: $justification
			originator: $originator
			experts: $experts
			icsCodes: $icsCodes
			tags: $tags
			classifications: $classifications
			enData: $enData
		) {
			id
			createdAt
			updatedAt
			stanNumber
			registrationNumber
			form
			edition
			cenWiNumber
			file {
				id
				name
				path
			}
			revision {
				id
				form
				registrationNumber
				edition
			}
			localizedTitle
			titles {
				en
				de
				fr
			}
			languages
			standardTypes {
				id
				name
			}
			pages
			domains {
				code
				name
			}
			workingGroups {
				code
				name
			}
			leader {
				id
				firstName
				lastName
				systemRoles
				picture {
					path
				}
			}
			scope
			originator {
				id
				userId
				firstName
				lastName
				email
			}
			justification {
				purpose
				revisionJustification
				technicalChanges {
					title
					change
				}
				consideredStandards {
					id
					name
				}
				externalConsideredStandards
				knownPatentIssue
				patentFile {
					path
				}
				patentReferences
			}
			tags {
				value
			}
			classifications {
				id
				number
				name
			}
			icsCodes {
				code
				name
				description
			}
			experts {
				id
				firstName
				lastName
				picture {
					path
				}
				domains {
					code
					name
				}
				workingGroups {
					code
					name
				}
			}
			remarks {
				id
				text
				remarkType
				createdBy {
					id
					firstName
					lastName
					picture {
						path
					}
				}
				createdAt
			}
		}
	}
`;

const STANDARD_TAGS = gql`
	query ($limit: Int!, $offset: Int!, $value: String!) {
		tags(limit: $limit, offset: $offset, value: $value) {
			value
		}
		tagsCount(value: $value)
	}
`;

const STANDARD_CLASSIFICATIONS = gql`
	query ($limit: Int!, $offset: Int!, $number: String, $name: String) {
		classifications(limit: $limit, offset: $offset, number: $number, name: $name) {
			id
			number
			name
		}
		classificationsCount
	}
`;

const STANDARD_ICS_CODES = gql`
	query ($limit: Int!, $offset: Int!, $name: String) {
		icsCodes(limit: $limit, offset: $offset, name: $name) {
			code
			name
			description
		}
		icsCodesCount
	}
`;

const SINGLE_STANDARD = gql`
	query ($id: Int!) {
		standard(id: $id) {
			id
			registrationNumber
			form
			edition
			cenWiNumber
			createdAt
			updatedAt
			form
			editorId
			isMigrated
			file {
				id
				name
				path
				size
			}
			stanNumber
			statuses
			transaction {
				id
				startDate
				targetDate
				completeDate
				stages {
					code
					name
					startDate
					targetDate
					completeDate
				}
			}
			domains {
				code
				name
			}
			revision {
				id
				registrationNumber
				form
				localizedTitle
			}
			localizedTitle
			languages
			pages
			workingGroups {
				code
				name
			}
			leader {
				id
				firstName
				lastName
				systemRoles
				picture {
					id
					path
				}
				deletedAt
			}
			wgs {
				id
				firstName
				lastName
				systemRoles
				picture {
					id
					path
				}
			}
			scope
			originator {
				id
				firstName
				lastName
				email
				userId
				phone
				company {
					id
					name
				}
				country {
					id
					name
				}
				positions {
					id
					name
				}
				deletedAt
			}
			justification {
				purpose
				revisionJustification
				externalConsideredStandards
				consideredStandards {
					id
					name
				}
				knownPatentIssue
				patentFile {
					id
					path
					name
					size
				}
				patentReferences
				technicalChanges {
					id
					title
					change
				}
			}
			experts {
				id
				firstName
				lastName
				picture {
					id
					path
				}
				workingGroups {
					code
					name
				}
				domains {
					code
					name
				}
				deletedAt
			}
			standardTypes {
				id
				name
			}
			titles {
				en
				fr
				de
			}
			tags {
				value
			}
			classifications {
				id
				name
				number
			}
			icsCodes {
				code
				name
			}
			remarks {
				id
				text
				remarkType
				stageUpdate {
					from {
						code
						name
					}
					to {
						code
						name
					}
				}
				createdAt
				createdBy {
					id
					firstName
					lastName
					picture {
						id
						path
					}
					deletedAt
				}
			}
			enName
			enPublicationDate
			enTitle
			cenWiNumber
		}
	}
`;

const ADD_REMARK = gql`
	mutation ($standardId: Int!, $remark: RemarkInput!) {
		createRemark(standardId: $standardId, remark: $remark) {
			id
			text
			remarkType
			standardId
			createdBy {
				id
				firstName
				lastName
				picture {
					path
				}
			}
			createdAt
		}
	}
`;

const UPDATE_STAGE = gql`
	mutation (
		$id: Int!
		$stages: [String!]!
		$comment: String # could be both illogical justification or just comment
		$targetDate: String
		$notify: NotifyInput
		$publicationData: StandardPublicationDataInput
	) {
		updateStage(
			id: $id
			stages: $stages
			comment: $comment
			targetDate: $targetDate
			forPublication: $publicationData
			notify: $notify
		) {
			id
		}
	}
`;

const GET_STANDARD_ATTACHMENTS = gql`
	query ($standardId: Int!) {
		attachmentsByStandard(standardId: $standardId) {
			id
			file {
				id
				name
				path
				size
				mimeType
			}
			description
			type
			createdAt
			createdBy {
				id
				firstName
				lastName
				picture {
					path
					name
				}
			}
		}
	}
`;

const ADD_ATTACHMENT_TO_STANDARD = gql`
	mutation ($standardId: Int!, $attachment: StandardAttachmentInput!) {
		createAttachment(standardId: $standardId, attachment: $attachment) {
			id
			file {
				path
			}
			description
			type
			createdAt
			createdBy {
				id
				firstName
				lastName
			}
		}
	}
`;

const DELETE_ATTACHMENT = gql`
	mutation ($id: Int!) {
		deleteAttachment(id: $id)
	}
`;

const PRICE_HISTORY = gql`
	query ($standardId: Int!, $limit: Int!, $offset: Int!) {
		standardPriceList(standardId: $standardId, limit: $limit, offset: $offset) {
			amount
			currency
			year
		}

		standardPriceCount(standardId: $standardId)
	}
`;

const UPDATE_PRICE = gql`
	mutation ($standardId: Int!, $amount: Float!) {
		updateStandardPrice(standardId: $standardId, amount: $amount) {
			amount
			currency
			year
		}
	}
`;

const ADD_PRICE = gql`
	mutation ($standardId: Int!, $amount: Float!) {
		addStandardPrice(standardId: $standardId, amount: $amount) {
			amount
			currency
			year
		}
	}
`;

const STANDARD_TRANSACTION_HISTORY = gql`
	query ($standardId: Int!, $limit: Int!, $offset: Int!) {
		standardTransactionHistory(standardId: $standardId, limit: $limit, offset: $offset) {
			stages {
				# StageType []
				code # string
				name # string
			}
			id
			startDate
			targetDate
			completeDate
		}
		standardTransactionHistoryCount(standardId: $standardId)
	}
`;

const STANDARD_CHANGES_HISTORY = gql`
	query ($standardId: Int!, $transactionId: Int!, $limit: Int!, $offset: Int) {
		standardChangesHistory(
			standardId: $standardId
			transactionId: $transactionId
			limit: $limit
			offset: $offset
		) {
			property
			history
			date
			user {
				firstName
				lastName
			}
		}
		standardChangesHistoryCount(standardId: $standardId, transactionId: $transactionId)
	}
`;

const ALL_STANDARD_STAGES = gql`
	query ($form: String, $showHistoricalStages: Boolean) {
		allStages(form: $form, showHistoricalStages: $showHistoricalStages) {
			code
			name
			immediatelyPublication
		}
	}
`;

const NEW_STAGE_OPTIONS = gql`
	query ($form: String, $standardId: Int) {
		allStages(form: $form, standardId: $standardId) {
			code
		}
	}
`;

const UPDATE_DRAFT = gql`
	mutation (
		$id: Int!
		$generalData: StandardDraftGeneralFormInput!
		$titles: StandardDraftTitlesFormInput!
		$draft: Int
		$attachments: [StandardDraftAttachmentFormInput!]!
		$justification: StandardDraftJustificationFormInput!
		$originator: StandardDraftOriginatorFormInput!
		$experts: StandardDraftExpertsFormInput!
		$remarks: [StandardDraftRemarkFormInput!]!
		$additionalInformation: StandardDraftAdditionalInfoFormInput!
		$enData: TransformationDataInput
	) {
		updateDraft(
			id: $id
			generalData: $generalData
			titles: $titles
			draft: $draft
			attachments: $attachments
			justification: $justification
			originator: $originator
			experts: $experts
			remarks: $remarks
			additionalInformation: $additionalInformation
			enData: $enData
		) {
			id
			generalData {
				stanNumber
				registrationNumber
				form
				edition
				isRevision
				revision {
					id
					form
					registrationNumber
					edition
				}
				languages
				standardTypes {
					id
					name
				}
				pages
				domains {
					code
					name
				}
				isWorkingGroupNull
				workingGroups {
					code
					name
				}
				scope
				leader {
					id
					firstName
					lastName
					systemRoles
					picture {
						path
					}
				}
			}
			titles {
				en
				de
				fr
			}
			originator {
				isAsdOriginator
				isExistOriginator
				id
				userId
				firstName
				lastName
				email
				phone
				company {
					id
					name
				}
				country {
					id
					name
				}
				positions {
					id
					name
				}
			}
			draft {
				id
				name
				path
			}
			justification {
				purpose
				revisionJustification
				technicalChanges {
					title
					change
				}
				consideredStandards {
					id
					name
				}
				externalConsideredStandards
				knownPatentIssue
				patentFile {
					id
					name
					path
				}
				patentReferences
			}
			additionalInformation {
				tags {
					value
				}
				classifications {
					id
					number
					name
				}
				icsCodes {
					code
					name
					description
				}
			}
			experts {
				experts {
					id
					firstName
					lastName
					picture {
						path
					}
					domains {
						code
						name
					}
					workingGroups {
						code
						name
					}
				}
				wgs {
					id
					firstName
					lastName
					picture {
						path
					}
				}
			}
			attachments {
				file {
					id
					path
				}
				type
				description
			}
			remarks {
				text
				remarkType
				createdBy {
					id
					firstName
					lastName
					picture {
						path
					}
				}
				createdAt
			}
		}
	}
`;

const MOVE_DRAFT_TO_NWP = gql`
	mutation ($id: Int!) {
		moveDraftToNWP(id: $id) {
			id
			form
			registrationNumber
			localizedTitle
			edition
			cenWiNumber
		}
	}
`;

const GET_PRIVATE_ATTACHMENT_BY_ID = gql`
	query ($id: Int!) {
		attachmentFile(id: $id)
	}
`;

const GET_PRIVATE_STANDARD_DRAFT_BY_ID = gql`
	query ($id: Int!) {
		standardFile(standardId: $id)
	}
`;

const GET_PRIVATE_DRAFT_BY_ID = gql`
	query ($id: Int!, $index: Int!) {
		attachmentDraftFile(id: $id, index: $index)
	}
`;

const INIT_STANDARDS_EXPORT = gql`
	mutation ($filter: StandardFilterInput) {
		initExportStandards(filter: $filter)
	}
`;

const CHECK_STANDARDS_EXPORT = gql`
	query ($id: String!) {
		exportStandards(id: $id) {
			progress
			completed
		}
	}
`;

const EXPORT_STANDSRDS_FILE = gql`
	query ($id: String!) {
		exportStandardFile(id: $id)
	}
`;

const CANCEL_STANDARDS_EXPORT = gql`
	mutation ($id: String!) {
		cancelExportStandards(id: $id)
	}
`;

const EDIT_STAGE = gql`
	mutation ($id: Int!, $transaction: StandardTransactionInput!) {
		updateStandardTransaction(id: $id, transaction: $transaction) {
			id
		}
	}
`;

export class StandardRepo {
	async getStandards(
		limit: number,
		offset: number,
		filter?: Partial<StandardCollectionFilter>
	): Promise<Page<Standard>> {
		const { data } = await stanWorksClient.query({
			query: STANDARD_LIST,
			variables: {
				limit: limit,
				offset: offset,
				filter: filter && mapStandardListFilter(filter),
			},
		});

		const newData = data.standardList.map((standard: StandardDTO) =>
			makeStandardFromStandardDTO(standard)
		);

		return { totalNumber: data.standardCount, data: newData };
	}

	async getNextRegNumber(form: string): Promise<string> {
		const { data } = await stanWorksClient.query({
			query: NEXT_REG_NUMBER,
			variables: {
				form: form,
			},
		});

		return data.nextRegNumber;
	}

	async getNextStanNumber(form: string): Promise<string> {
		const { data } = await stanWorksClient.query({
			query: NEXT_STAN_NUMBER,
			variables: {
				form: form,
			},
		});

		return data.nextStanNumber;
	}

	async getAllStandardTypes(): Promise<Array<Type>> {
		const { data } = await stanWorksClient.query({
			query: ALL_STANDARD_TYPES,
		});

		return data.allStandardTypes;
	}

	async getLatestStandards(
		limit: number,
		offset: number,
		registrationNumber: string
	): Promise<Array<Standard>> {
		const { data } = await stanWorksClient.query<StandardListDTO>({
			query: LATEST_STANDARDS,
			variables: {
				limit: limit,
				offset: offset,
				registrationNumber: registrationNumber,
			},
		});

		return data.latestStandards.map(dto => makeStandardFromStandardDTO(dto));
	}

	async getStandardAllocationCount(): Promise<number> {
		try {
			const { data } = await stanWorksClient.query({
				query: STANDARD_COUNTS,
				variables: {
					filter: {
						domain: null,
						workingGroup: null,
						isAssigned: false,
						orderField: 'title',
						orderDirection: 'DESC',
					},
				},
			});

			return data?.standardCount;
		} catch (e) {
			return 0;
		}
	}

	async getStandardList(limit: number, offset: number, filter: any) {
		const { data } = await stanWorksClient.query({
			query: STANDARD_LIST,
			variables: {
				limit: limit,
				offset: offset,
				filter: filter,
			},
		});

		return data;
	}

	async getStandardById(id: number): Promise<StandardDetailed | null> {
		try {
			const { data } = await stanWorksClient.query({
				query: SINGLE_STANDARD,
				variables: {
					id,
				},
			});

			return makeStandardDetailedFromStandardDetailedDTO(data.standard);
		} catch (e) {
			return null;
		}
	}

	async updateStage(
		standardId: number,
		updateStageValues: UpdateStageForm,
		ableToPublish: boolean
	): Promise<boolean> {
		const updateStage = mapUpdateStageDto(updateStageValues, ableToPublish);
		const { data } = await stanWorksClient.query({
			query: UPDATE_STAGE,
			variables: {
				id: standardId,
				...updateStage,
			},
		});

		return data.updateStage;
	}

	async addRemarkToStandard(standardId: number, text: string): Promise<StandardDetailedRemark> {
		const { data } = await stanWorksClient.mutate({
			mutation: ADD_REMARK,
			variables: {
				standardId,
				remark: {
					text,
				},
			},
		});

		return data.createRemark;
	}

	async getOriginatorList(limit: number, offset: number, filter: Partial<any>) {
		const { data } = await stanWorksClient.query({
			query: ORIGINATOR_LIST,
			variables: {
				limit: limit,
				offset: offset,
				filter: filter,
			},
		});

		return data.originatorList;
	}

	async updateStandard(creation: StandardUpdate) {
		const { data } = await stanWorksClient.mutate({
			mutation: UPDATE_STANDARD,
			variables: {
				id: creation.id,
				generalData: creation.generalData,
				titles: creation.titles,
				draft: creation.draft,
				justification: creation.justification,
				originator: creation.originator,
				experts: creation.experts,
				// remarks: creation.remarks,
				icsCodes: creation.icsCodes,
				tags: creation.tags,
				classifications: creation.classifications,
				enData: creation.enData,
			},
		});

		return data.updateStandard;
	}

	//TODO: Fix it when we gonna refactor it and add pagination controller

	// async getAllAdditionalInformation(limit: number): Promise<Partial<Standard>> {
	// 	const { data } = await stanWorksClient.query({
	// 		query: ALL_ADDITIONAL_INFORMATION,
	// 		variables: {
	// 			limit: limit,
	// 			offset: 0,
	// 		},
	// 	});
	//
	// 	return makeStandardAdditionalInformationFromStandardAdditionalInformationDTO(data);
	// }

	async getTags(limit: number, offset: number, value: string) {
		const { data } = await stanWorksClient.query({
			query: STANDARD_TAGS,
			variables: {
				limit: limit,
				offset: offset,
				value: value,
			},
		});

		return { data: data.tags, totalNumber: data.tagsCount };
	}

	async getClassifications(limit: number, offset: number, inputValue: string) {
		const { data } = await stanWorksClient.query({
			query: STANDARD_CLASSIFICATIONS,
			variables: {
				limit: limit,
				offset: offset,
				number: inputValue,
				name: inputValue,
			},
		});

		return data;
	}

	async getIcsCodes(limit: number, offset: number, inputValue: string) {
		const { data } = await stanWorksClient.query({
			query: STANDARD_ICS_CODES,
			variables: {
				limit: limit,
				offset: offset,
				code: inputValue,
				name: inputValue,
			},
		});

		return data;
	}

	async getAttachmentsByStandardId(id: number): Promise<AttachmentResponse[]> {
		const { data } = await stanWorksClient.query<{ attachmentsByStandard: AttachmentResponse[] }>({
			query: GET_STANDARD_ATTACHMENTS,
			variables: {
				standardId: id,
			},
		});

		return data.attachmentsByStandard.map(attachment => ({
			...attachment,
		}));
	}

	async addAttachmentToStandard(id: number, fileData: AttachmentUpload) {
		const { data } = await stanWorksClient.mutate({
			mutation: ADD_ATTACHMENT_TO_STANDARD,
			variables: {
				standardId: id,
				attachment: {
					fileId: fileData.file.fileId,
					type: fileData.attachmentType.value || null,
					description: fileData.attachmentDescription || null,
				},
			},
		});

		return data.createAttachment;
	}

	async deleteAttachmentFromStandard(id: number) {
		const { data } = await stanWorksClient.mutate({
			mutation: DELETE_ATTACHMENT,
			variables: {
				id,
			},
		});

		return data.deleteAttachment;
	}

	async getTransactionHistory(id: number, limit: number, offset: number) {
		const { data } = await stanWorksClient.query<StandardTransactionHistoryResponse>({
			query: STANDARD_TRANSACTION_HISTORY,
			variables: {
				standardId: id,
				limit,
				offset,
			},
		});

		return {
			standardTransactionHistoryCount: data.standardTransactionHistoryCount,
			standardTransactionHistory: data.standardTransactionHistory.map(mapTransactionHistoryDto),
		};
	}

	async getStageUpdateHistory(
		standardId: number,
		transactionId: number,
		limit: number,
		offset: number
	): Promise<StandardStageUpdateHistoryResponseParsed> {
		const { data } = await stanWorksClient.query<StandardStageUpdateHistoryResponse>({
			query: STANDARD_CHANGES_HISTORY,
			variables: {
				standardId,
				transactionId,
				limit,
				offset,
			},
		});

		return {
			standardChangesHistory: mapStageHistoryChanges(data.standardChangesHistory),
			standardChangesHistoryCount: data.standardChangesHistoryCount,
		};
	}

	async updateDraft(draftId: number, update: DraftUpdate) {
		const { data } = await stanWorksClient.mutate({
			mutation: UPDATE_DRAFT,
			variables: {
				id: draftId,
				generalData: update.generalData,
				titles: update.titles,
				draft: update.draft,
				attachments: update.attachments,
				justification: update.justification,
				originator: update.originator,
				experts: update.experts,
				remarks: update.remarks,
				additionalInformation: update.additionalInformation,
				enData: update.enData,
			},
		});

		return data.updateDraft;
	}

	async moveDraftToNWP(draftId: number) {
		const { data } = await stanWorksClient.mutate({
			mutation: MOVE_DRAFT_TO_NWP,
			variables: {
				id: draftId,
			},
		});

		return data.moveDraftToNWP.id as number;
	}

	async getPriceHistory(standardId: number, limit: number, offset: number) {
		const { data } = await stanWorksClient.query<PriceHistory>({
			query: PRICE_HISTORY,
			variables: {
				standardId,
				limit,
				offset,
			},
		});
		return data;
	}

	async updatePrice(standardId: number, amount: number) {
		const { data } = await stanWorksClient.mutate<PriceHistoryItem>({
			mutation: UPDATE_PRICE,
			variables: {
				standardId,
				amount,
			},
		});
		return data;
	}

	async addPrice(standardId: number, amount: number) {
		const { data } = await stanWorksClient.mutate<PriceHistoryItem>({
			mutation: ADD_PRICE,
			variables: {
				standardId,
				amount,
			},
		});
		return data;
	}

	async getAllStagesList(form?: string, showHistoricalStages?: boolean): Promise<StandardStage[]> {
		const { data } = await stanWorksClient.query<{ allStages: StandardStageArgs[] }>({
			query: ALL_STANDARD_STAGES,
			variables: {
				form,
				showHistoricalStages,
			},
		});

		return data.allStages.map((stage, index) =>
			makeStandardStageFromArgs({
				...stage,
				id: index,
			})
		);
	}

	async getNewStageOptions(form: string, standardId: number): Promise<string[]> {
		const { data } = await stanWorksClient.query<{ allStages: { code: string }[] }>({
			query: NEW_STAGE_OPTIONS,
			variables: {
				form,
				standardId,
			},
		});

		return data.allStages.map(({ code }) => code);
	}

	async getPrivateAttachmentLinkById(id: number): Promise<string> {
		const { data } = await stanWorksClient.query<{ attachmentFile: string }>({
			query: GET_PRIVATE_ATTACHMENT_BY_ID,
			variables: {
				id,
			},
		});

		return data.attachmentFile;
	}

	async getPrivateStandardDraftLinkById(id: number): Promise<string> {
		const { data } = await stanWorksClient.query<{ standardFile: string }>({
			query: GET_PRIVATE_STANDARD_DRAFT_BY_ID,
			variables: {
				id,
			},
		});

		return data.standardFile;
	}

	async getPrivateDraftLinkById(id: number, index: number): Promise<string> {
		const { data } = await stanWorksClient.query<{ attachmentDraftFile: string }>({
			query: GET_PRIVATE_DRAFT_BY_ID,
			variables: {
				id,
				index,
			},
		});

		return data.attachmentDraftFile;
	}

	async initStandardsExport(filter: StandardCollectionFilter) {
		const { data } = await stanWorksClient.mutate({
			mutation: INIT_STANDARDS_EXPORT,
			variables: {
				filter: mapStandardListFilter(filter),
			},
		});
		return data.initExportStandards as string;
	}

	async checkStandardsExport(id: string) {
		const { data } = await stanWorksClient.query({
			query: CHECK_STANDARDS_EXPORT,
			variables: {
				id,
			},
		});
		return data.exportStandards as ExportStatus;
	}

	async getExportStandardsFileLink(id: string) {
		const { data } = await stanWorksClient.query({
			query: EXPORT_STANDSRDS_FILE,
			variables: {
				id,
			},
		});
		return data.exportStandardFile as string;
	}

	async cancelStandardsExport(id: string) {
		await stanWorksClient.mutate({
			mutation: CANCEL_STANDARDS_EXPORT,
			variables: {
				id,
			},
		});
	}

	async editStage(id: number, editStageForm: EditStageForm) {
		await stanWorksClient.mutate({
			mutation: EDIT_STAGE,
			variables: {
				id,
				transaction: mapEditStage(editStageForm),
			},
		});
	}
}
