import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '@asd-stan/i18n/i18n';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getModalService, getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { EditStageForm } from '@asd-stan/standard/domain/stage-update.entity';
import { getStandardService } from '@asd-stan/standard/infrastructure/getters';
import { Button } from '@components/button/button';
import { Checkbox } from '@components/checkbox/checkbox';
import { FormDatePicker } from '@components/form-date-picker/form-date-picker';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

import { DataSection, TitleWrapper } from '../../stage-update/additional/additional.styled';
import {
	ButtonsContainer,
	CloseBtn,
	StageContainer,
	StageHeader,
} from '../../stage-update/stage-update.styled';

import { ReactComponent as CloseIcon } from './assets/close.svg';

const validationSchema = yup.object().shape({
	startDate: yup
		.date()
		.required(i18n.t('standard.singleStandard.stageAndStatus.editModal.required')),
});

export const EditStageModal = ({
	id,
	startDate,
	targetDate,
	completeDate,
	onSubmit,
}: {
	id: number;
	startDate: null | Date;
	targetDate: null | Date;
	completeDate: null | Date;
	onSubmit(): void;
}) => {
	const { t } = useTranslation();
	const modalService = getModalService();
	const standardService = getStandardService();
	const [targetDateDisabled, setTargetDateDisabled] = useState(targetDate === null);
	const toasterService = getToasterService();

	const handleSubmit = async (values: EditStageForm) => {
		try {
			await standardService.editStage(id, values);
			closeModal();
			toasterService.showToast(
				ToasterStatus.success,
				t('standard.singleStandard.stageAndStatus.editModal.success')
			);
			onSubmit();
		} catch (err: any) {}
	};

	const closeModal = () => modalService.closeModal();

	const initialValues = {
		startDate,
		targetDate,
		completeDate,
	};

	const isDateBeforeTheOther = (date: null | Date, compareWith: null | Date) =>
		moment(date).isBefore(compareWith);

	return (
		<Formik
			onSubmit={handleSubmit}
			initialValues={initialValues}
			validationSchema={validationSchema}>
			{({ values, setFieldValue, isSubmitting }) => (
				<StageContainer as={Form}>
					<StageHeader>{t('standard.singleStandard.stageAndStatus.editModal.title')}</StageHeader>
					<CloseBtn onClick={closeModal}>
						<CloseIcon />
					</CloseBtn>
					<TitleWrapper style={{ width: '100%' }}>
						<DataSection>
							<Field
								component={FormDatePicker}
								label="Start date"
								showError
								mandatory
								placeholder="Choose date"
								name="startDate"
								fullWidth
								onChange={(_: unknown, date: null | Date) => {
									if (isDateBeforeTheOther(values.targetDate, date)) {
										setFieldValue('targetDate', null);
									}
									if (isDateBeforeTheOther(values.completeDate, date)) {
										setFieldValue('completeDate', null);
									}
								}}
							/>
							<Field
								component={FormDatePicker}
								label="Target date"
								mandatory
								placeholder="Choose date"
								name="targetDate"
								disabled={targetDateDisabled}
								minDate={values.startDate}
								fullWidth
							/>
							<div className="checkbox">
								<Checkbox
									label={t('standard.singleStandard.stageUpdateModal.additional.noTargetDate')}
									checked={targetDateDisabled}
									onChange={v => {
										setTargetDateDisabled(v);
										if (v) {
											setFieldValue('targetDate', null);
										}
									}}
								/>
							</div>
							<Field
								component={FormDatePicker}
								label="Actual completition"
								mandatory={false}
								placeholder="Choose date"
								name="completeDate"
								minDate={values.startDate}
								fullWidth
							/>
						</DataSection>
					</TitleWrapper>
					<ButtonsContainer>
						<Button
							fullWidth
							secondary
							disabled={isSubmitting}
							title={t('standard.singleStandard.stageUpdateModal.buttons.cancel')}
							onClick={closeModal}
						/>
						<Button
							fullWidth
							title={t('standard.singleStandard.stageUpdateModal.buttons.update')}
							disabled={isSubmitting}
							type="submit"
						/>
					</ButtonsContainer>
				</StageContainer>
			)}
		</Formik>
	);
};
