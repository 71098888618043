import { Row } from '@tanstack/react-table';
import React from 'react';

import { Expert } from '@asd-stan/standard/domain/standard-detailed.entity';
import { Avatar } from '@components/avatar/avatar';

import { ExpertRow } from './experts-row.styled';

interface Props {
	row: Row<Expert>;
}

export const ProjectLeader: React.FC<Props> = ({ row }) => {
	const deletedStyle = { opacity: 0.6 };
	const deleted = !!row.original.deletedAt;
	return (
		<ExpertRow>
			<Avatar deleted={deleted} />
			<p style={deleted ? deletedStyle : undefined}>{row.getValue('fullName')}</p>
		</ExpertRow>
	);
};
