import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { languages } from '@asd-stan/common/enums';
import { getDomainService } from '@asd-stan/domain/infrastructure/getters';
import { FileUploadController } from '@asd-stan/file/domain/file-upload-controller';
import { calculateOffset } from '@asd-stan/helpers/app-utils';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getModalService, getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { CatalogItem } from '@asd-stan/standard/domain/catalog-item.entity';
import { forms } from '@asd-stan/standard/domain/enums';
import { getCatalogService, getStandardService } from '@asd-stan/standard/infrastructure/getters';
import {
	catalogModalScheme,
	catalogModalSchemeWithOptionalFields,
} from '@asd-stan/standard/validation-schemas/catalog-modal.schema';
import { Button } from '@components/button/button';
import { DatePicker } from '@components/date-picker/date-picker';
import { FileDropzone } from '@components/file-dropzone/file-dropzone';
import { FormCheckbox } from '@components/form-checkbox/form-checkbox';
import { FormInputParagraph } from '@components/form-input-paragraph/form-input-paragraph';
import { FormInputPopup } from '@components/form-input-popup/form-input-popup';
import { FormInputPrice } from '@components/form-input-price/form-input-price';
import { FormInput } from '@components/form-input/form-input';
import { FormSelect, Option } from '@components/form-select/form-select';
import { FormSelectAsyncCreatablePagination } from '@components/form-select/form-select-async-creatable-pagination';
import { AdditionalType } from '@components/form-select/form-select-async-pagination';
import { Flex } from '@components/utility/flex';
import { Field, Form, Formik, FormikValues } from 'formik';

import { StyledEditCatalogItem } from '@asd-stan/standard/components/catalog-list/modals/edit-catalog-item.styled';
import { StyledModalButtonsContainer } from '@asd-stan/user/components/invite-users/modals/modal-buttons-container.styled';

interface EditCatalogItemProps {
	item: CatalogItem;
}

const fileTypes = {
	'image/png': ['.jpg', '.jpeg', '.png'],
};

const standardTypes = {
	'application/pdf': ['.pdf'],
};

const LIMIT = 6;

export const statusOptions = [
	{ value: 'published', label: 'Published' },
	{ value: 'superseded', label: 'Superseded' },
	{ value: 'cancelled', label: 'Cancelled' },
	{ value: 'withdrawn', label: 'Withdrawn' },
];

export const EditCatalogItem: React.FC<EditCatalogItemProps> = observer(({ item }) => {
	const modalService = getModalService();
	const toasterService = getToasterService();
	const catalogService = getCatalogService();
	const standardService = getStandardService();
	const domainService = getDomainService();
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const fileUploadController = new FileUploadController();

	const buttonOptions: Option[] = [
		{ label: t('standard.catalog.modal.purchaseButtonActive'), value: 'true' },
		{ label: t('standard.catalog.modal.purchaseButtonInactive'), value: 'false' },
	];

	const handleDiscardChanges = () => {
		modalService.closeModal();
	};

	const initialValues = {
		name: item.name,
		title: item.localizedTitle,
		publicationDate: new Date(item.publicationDate) || null,
		availableForPurchase: {
			value: item.availableForPurchase.toString(),
			label: item.availableForPurchase
				? t('standard.catalog.modal.purchaseButtonActive')
				: t('standard.catalog.modal.purchaseButtonInactive'),
		},
		price: item.price.amount,
		description: item.description,
		coverPage: item.coverPage
			? [{ ...item.coverPage, fileId: item.coverPage.id, title: item.coverPage?.name }]
			: null,
		isTop: item.isTop,
		form: forms.find(form => form.value === item.form),
		registrationNumber: item.registrationNumber,
		types: item.types.map(({ id, name }) => ({ label: name, value: id })),
		edition: item.edition,
		languages: languages.filter(({ value }) => item.languages.includes(value)),
		domains: item.domains.map(({ code, name }) => ({ label: name, value: code })),
		scope: item.scope,
		internalReferences: item.internalReferences.map(({ id, name }) => ({ label: name, value: id })),
		externalReferences: item.externalReferences,
		tags: item.tags.map(t => ({ label: t, value: t })),
		status: {
			label: statusOptions.find(({ value }) => value === item.status)?.label,
			value: item.status,
		},
		fileId: item.preview ? [item.preview] : [],
	};

	const onSubmit = async (values: FormikValues) => {
		setIsLoading(true);

		await catalogService
			.editCatalogItem(item.id, values)
			.then(() => modalService.closeModal())
			.then(() =>
				toasterService.showToast(
					ToasterStatus.success,
					t('standard.catalog.toaster.updateItemSuccess')
				)
			)
			.finally(catalogService.catalog.loadCurrentPage);

		setIsLoading(false);
	};

	useEffect(() => {
		standardService.getAllTypes();
		standardService.getStandardList(20, 0, false);
	}, []);

	const typeOptions = standardService.allTypes.map(type => {
		return { label: type.name, value: type.id };
	});

	const domainOptions = domainService.domains.map(domain => {
		return { label: `${domain.code} ${domain.name}`, value: domain.code };
	});

	const consideredOptions = standardService.standardList
		.filter(({ id }) => id !== item.standardId)
		.map(standard => {
			return {
				label: `${standard.stanNumber} ${standard.form} ${standard.edition}`,
				value: standard.id,
				customLabel: (
					<Flex style={{ whiteSpace: 'nowrap' }} $justify="space-between" $align="center">
						{`${standard.stanNumber} ${standard.form}
					${
						standard.localizedTitle && standard.localizedTitle.length > 100
							? standard.localizedTitle.substring(0, 95) + '...'
							: standard.localizedTitle
					} 
					${standard.edition}`}
					</Flex>
				),
			};
		});

	const fieldsShouldBeOptional = catalogService.checkIfFieldsShouldBeOptional(item.publisher);

	return (
		<StyledEditCatalogItem>
			<h1>{t('standard.catalog.modal.title')}</h1>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validationSchema={
					fieldsShouldBeOptional ? catalogModalSchemeWithOptionalFields : catalogModalScheme
				}
				validateOnChange
				validateOnBlur={false}>
				{({ values, handleSubmit, setFieldError, setFieldValue, validateForm }) => {
					return (
						<Form>
							<Field
								component={FormInput}
								name="name"
								value={values.name}
								title={t('standard.catalog.modal.nameTitle')}
								mandatory
								fullWidth
								showError
							/>
							<Field
								component={FormInput}
								name="title"
								value={values.title}
								title={t('standard.catalog.modal.titleTitle')}
								mandatory
								fullWidth
								showError
							/>
							<Field
								component={DatePicker}
								name="publicationDate"
								value={values.publicationDate}
								title={t('standard.catalog.modal.dateTitle')}
								mandatory
								showError
								dateFormat="MM/yyyy"
								showMonthYearPicker
							/>
							<Field
								component={FormSelect}
								name="availableForPurchase"
								value={values.availableForPurchase}
								options={buttonOptions}
								title={t('standard.catalog.modal.purchaseButtonTitle')}
								showError
								notClearable
							/>
							<Field
								component={FormSelect}
								name="status"
								value={values.status}
								options={statusOptions}
								title={t('standard.catalog.modal.show')}
								showError
								mandatory
								notClearable
							/>
							<Field
								component={FormInputPrice}
								name="price"
								value={values.title}
								title={t('standard.catalog.modal.priceTitle')}
								mandatory
								showError
								currency={item.price.currency}
								hint={t('standard.catalog.modal.priceHint')}
								setTouchedOnBlur
							/>
							<Field
								component={FormInputParagraph}
								name="description"
								value={values.description}
								maxLength={5000}
								title={t('standard.catalog.modal.abstractTitle')}
								fullWidth
								showError
							/>
							<Field
								component={FormSelect}
								name="form"
								title={t('standard.createNWP.general.formTitle')}
								options={forms}
								mandatory={!fieldsShouldBeOptional}
								showError
							/>
							<Field
								component={FormInput}
								name="registrationNumber"
								value={values.name}
								title="Registration number"
								mandatory={!fieldsShouldBeOptional}
								showError
							/>
							<Field
								component={FormSelect}
								name="types"
								title="Type"
								options={typeOptions}
								isMulti
								showError
							/>
							<Field
								component={FormInput}
								name="edition"
								value={values.name}
								title="Edition"
								mandatory={!fieldsShouldBeOptional}
								showError
							/>
							<Field
								component={FormSelect}
								name="languages"
								title="Languages"
								options={languages}
								mandatory={!fieldsShouldBeOptional}
								showError
								isMulti
								fullWidth
							/>
							<Field
								component={FormSelect}
								name="domains"
								title="Domain"
								options={domainOptions}
								mandatory={!fieldsShouldBeOptional}
								showError
								isMulti
								fullWidth
							/>
							<Field
								component={FormInputParagraph}
								name="scope"
								value={values.name}
								title="Scope"
								maxLength={5000}
								mandatory={!fieldsShouldBeOptional}
								showError
								fullWidth
							/>
							<Field
								component={FormSelect}
								name="internalReferences"
								title="Other standards in ASD-STAN to be considered "
								options={consideredOptions}
								showError
								isMulti
								fullWidth
							/>
							<Field
								component={FormInputPopup}
								name="externalReferences"
								title={t('standard.createNWP.justification.externalConsideredStandards')}
								fullWidth
								showError
								enableCopy
								maxLength={500}
							/>
							<Field
								component={FormSelectAsyncCreatablePagination}
								name="tags"
								title={t('standard.createNWP.additionalInformation.tags')}
								onCreateOption={async (value: string) => {
									if (value.length > 255 || value.length < 2) {
										setFieldError(
											'tags',
											t('user.userInvite.fieldErrors.length', { number: '2-255' })
										);
										return;
									}
									const newTag = { label: value.trim(), value: value.trim() };
									setFieldValue('tags', [...values.tags, newTag]);
								}}
								fullWidth
								isMulti
								defaultOptions
								loadOptions={async (search: string, _: unknown, { page }: AdditionalType) => {
									const offset = calculateOffset(page, LIMIT);
									const count = await standardService.getTags(LIMIT, offset, search);
									const updateTags = standardService.tags!.map(tag => {
										return {
											label: tag.value,
											value: tag.value,
										};
									});
									const hasMore = page < Math.ceil(count / 6);
									return {
										options: updateTags,
										hasMore,
										additional: {
											page: page + 1,
										},
									};
								}}
								showError
								showCheckboxOption
							/>
							<div style={{ width: '100%' }}>
								<Field
									component={FileDropzone}
									name="coverPage"
									title={t('standard.catalog.modal.dropzoneTitle')}
									fileTypes={fileTypes}
									maxFiles={1}
									maxSize={5242880}
									multiple
									controller={fileUploadController}
									className="cover-page"
								/>
							</div>
							<Field
								component={FormCheckbox}
								name="isTop"
								isChecked={values.isTop}
								label={t('standard.catalog.modal.checkboxTitle')}
								fullWidth
								showError
								className="is-top"
							/>
							<div style={{ width: '100%' }}>
								<Field
									component={FileDropzone}
									name="fileId"
									title={t('standard.catalog.modal.uploadStandard')}
									fileTypes={standardTypes}
									maxFiles={1}
									maxSize={20971520}
									multiple
									controller={fileUploadController}
									className="cover-page"
									mandatory
									showError
								/>
							</div>
							<StyledModalButtonsContainer>
								<Button
									secondary
									title={t('standard.catalog.modal.cancelButton')}
									onClick={handleDiscardChanges}
								/>
								<Button
									disabled={isLoading}
									title={t('standard.catalog.modal.saveButton')}
									onClick={async () => {
										validateForm().then(errors => {
											if (errors && !(Object.keys(errors).length === 0)) {
												toasterService.showToast(
													ToasterStatus.error,
													t('ballot.createBallot.validationErrorToast')
												);
											}
										});
										handleSubmit();
									}}
								/>
							</StyledModalButtonsContainer>
						</Form>
					);
				}}
			</Formik>
		</StyledEditCatalogItem>
	);
});
