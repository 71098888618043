import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';

import { useRequest } from '@asd-stan/helpers/use-request';
import { getMeetingService } from '@asd-stan/meeting/infrastructure/getters';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getModalService, getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { ReactComponent as EditIcon } from '@asd-stan/ui-kit/assets/icons/edit.svg';
import { BreadCrumbs } from '@components/bread-crumbs/bread-crumbs';
import { Button } from '@components/button/button';
import { IconButton } from '@components/button/icon-button';
import { Loading } from '@components/loading/loading';
import { PageTitle } from '@components/page-title/page-title';
import { Tabs } from '@components/tabs/tabs';
import { Flex } from '@components/utility/flex';

import { ReactComponent as DeleteIcon } from './assets/bin.svg';
import { StyledLoaderContainer, StyledModal, StyledSingleMeeting } from './single-meeting.styled';
import { Files } from './tabs/files/files';
import { General } from './tabs/general/general';

import { StyledButtonGroup } from '@components/utility/button-group.styled';
import { ContentContainer } from '@components/utility/content-container.styled';

const DeleteMeetingModal = ({
	onClose: handleClose,
	deleteMeeting,
}: {
	onClose(): void;
	deleteMeeting(): Promise<void>;
}) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const handleDelete = async () => {
		setLoading(true);
		await deleteMeeting();
		handleClose();
	};

	return (
		<StyledModal>
			<div className="header">{t('meeting.singleMeeting.deleteMeetingModal.title')}</div>
			<div className="title">{t('meeting.singleMeeting.deleteMeetingModal.message')}</div>
			<div className="buttons">
				<Button
					fullWidth
					secondary
					title={t('meeting.singleMeeting.deleteMeetingModal.cancel')}
					onClick={handleClose}
					disabled={loading}
				/>
				<Button
					fullWidth
					title={t('meeting.singleMeeting.deleteMeetingModal.confirm')}
					onClick={handleDelete}
					disabled={loading}
				/>
			</div>
		</StyledModal>
	);
};

enum TabKey {
	General = 'general',
	Files = 'files',
}

export const SingleMeeting = () => {
	const { t } = useTranslation();
	const params = useParams();
	const navigate = useNavigate();
	const id = Number(params.id);
	const meetingService = getMeetingService();
	const modalService = getModalService();
	const toasterService = getToasterService();
	const {
		data: meeting,
		isLoading: meetingLoading,
		invalidate: invalidateMeeting,
	} = useRequest(useCallback(() => meetingService.getSingleMeeting(id), [id]));

	const tabList = [
		{ tabKey: TabKey.General, title: t('meeting.singleMeeting.tabs.general') },
		{
			tabKey: TabKey.Files,
			title: t('meeting.singleMeeting.tabs.files'),
		},
	];

	const getTabComponent = (key: TabKey) => {
		if (!meeting) {
			return null;
		}
		switch (key) {
			case TabKey.General:
				return <General meeting={meeting} />;

			case TabKey.Files:
				return <Files id={id} meeting={meeting} onFilesUpadate={invalidateMeeting} />;
		}
	};

	const deleteMeeting = async () => {
		try {
			await meetingService.deleteMeeting(id);
			toasterService.showToast(ToasterStatus.success, t('meeting.singleMeeting.meetingDeleted'));
			navigate('/meetings');
		} catch (err: any) {
			console.error(err);
		}
	};

	if (meetingLoading || !meeting) {
		return (
			<ContentContainer>
				<StyledLoaderContainer>
					<Flex $align="center" $justify="center" $direction="column">
						<LogoIcon />
						<Loading horizontal />
					</Flex>
				</StyledLoaderContainer>
			</ContentContainer>
		);
	}

	return (
		<StyledSingleMeeting>
			<ContentContainer>
				<BreadCrumbs />
				<Flex $justify="space-between" $align="flex-start">
					<PageTitle title={meeting.form.title} />
					<StyledButtonGroup>
						{meetingService.checkIfCurrentUserIsAbleToDeleteMeeting(meeting.createdById) && (
							<IconButton
								icon={<DeleteIcon />}
								onClick={() =>
									modalService.openModal(
										<DeleteMeetingModal
											onClose={() => modalService.closeModal()}
											deleteMeeting={deleteMeeting}
										/>
									)
								}
							/>
						)}
						{meetingService.checkIfCurrentUserIsAbleToUpdateMeeting(meeting.createdById) && (
							<IconButton
								icon={<EditIcon />}
								onClick={() => navigate(`/meetings/edit/${params.id}`)}
							/>
						)}
					</StyledButtonGroup>
				</Flex>
				<Tabs tabLists={tabList} basePath={`/meetings/${id}`}>
					<Routes>
						<Route path="*" element={<Navigate to="general" />} />
						{tabList.map(({ tabKey }) => (
							<Route key={tabKey} path={tabKey} element={getTabComponent(tabKey)} />
						))}
					</Routes>
				</Tabs>
			</ContentContainer>
		</StyledSingleMeeting>
	);
};
