import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ProjectLeader } from '@asd-stan/standard/components/general/project-leader/project-leader';
import { forms } from '@asd-stan/standard/domain/enums';
import {
	Leader,
	Revision,
	StandardDomain,
	WorkingGroup,
} from '@asd-stan/standard/domain/standard-detailed.entity';

import { SingleStandardField } from '../../single-standard-field';
import {
	GeneralLinkStyles,
	StandardDisplayContainer,
	StandardDisplayField,
	StandardDisplayFieldHeader,
	StandardDisplayFieldValue,
	StandardDisplayFieldValueAbsent,
	StandardDisplayRow,
	TitleContainer,
} from '../../single-standard.styled';

import { DomainsWgroups } from './domains-wgroups/domains-wgroups';

interface Props {
	form?: string;
	edition?: string;
	registrationNumber?: string;
	stanNumber?: string;
	isRevision?: boolean | null;
	revision?: Revision | null;
	languages?: string[];
	pages?: number;
	isWorkingGroupNull?: boolean;
	workingGroups?: WorkingGroup[];
	standardTypes?: {
		id: number;
		name: string;
	}[];
	domains?: StandardDomain[];
	scope?: string;
	projectLeader?: Leader;
	cenWiNumber?: string | null;
	titles: {
		[key: string]: string;
	};
}

function transformLanguage(lang: string): string | undefined {
	const languageNames = new Intl.DisplayNames(['en'], {
		type: 'language',
	});

	const fullLang = languageNames.of(lang);

	if (!fullLang) {
		return '';
	}

	return fullLang;
}

export const General: React.FC<Props> = observer(props => {
	const { t } = useTranslation();

	const languageNames = new Intl.DisplayNames(['en'], {
		type: 'language',
	});
	const titles = Object.values(props.titles ?? {});
	const titlesKeys = Object.keys(props.titles ?? {});

	return (
		<StandardDisplayContainer>
			<StandardDisplayRow>
				<SingleStandardField
					header={t('standard.singleStandardPage.general.form')}
					value={forms.find(({ value }) => props.form === value)?.label ?? props.form}
					fallbackText={t('standard.singleStandardPage.general.formAbsent')}
				/>
				<SingleStandardField
					header={t('standard.singleStandardPage.general.regNum')}
					value={props.registrationNumber}
					fallbackText={t('standard.singleStandardPage.general.regNumAbsent')}
				/>
			</StandardDisplayRow>
			<StandardDisplayRow>
				<SingleStandardField
					header={t('standard.singleStandardPage.general.stanNum')}
					value={props.stanNumber}
					fallbackText={t('standard.singleStandardPage.general.stanNumAbsent')}
				/>
				<SingleStandardField
					header={t('standard.singleStandardPage.general.revision')}
					value={
						props.isRevision
							? props.isRevision === null
								? null
								: props.isRevision
								  ? t('standard.singleStandardPage.general.revisionTrue')
								  : t('standard.singleStandardPage.general.revisionFalse')
							: props.revision
							  ? t('standard.singleStandardPage.general.revisionTrue')
							  : t('standard.singleStandardPage.general.revisionFalse')
					}
					fallbackText={t('standard.singleStandardPage.general.revisionAbsent')}
				/>
			</StandardDisplayRow>
			{props.revision ? (
				<StandardDisplayField>
					<StandardDisplayFieldHeader>
						{t('standard.singleStandardPage.general.standardToBeSuperseded')}
					</StandardDisplayFieldHeader>
					<Link
						target="_blank"
						style={GeneralLinkStyles}
						to={`/standards/detailed/${props.revision.id}/general`}>
						{props.revision.registrationNumber} {props.revision.form}{' '}
						{props.revision.localizedTitle}
					</Link>
				</StandardDisplayField>
			) : null}
			<StandardDisplayRow>
				<SingleStandardField
					header={t('standard.singleStandardPage.general.edition')}
					value={
						props.edition && props.edition.length > 80
							? `${props.edition.slice(0, 80)}...`
							: props.edition
					}
					fallbackText={t('standard.singleStandardPage.general.editionAbsent')}
				/>
				<SingleStandardField
					header={t('standard.singleStandardPage.general.languages')}
					value={props.languages?.map(transformLanguage).toString().replaceAll(',', ', ')}
					fallbackText={t('standard.singleStandardPage.general.languagesAbsent')}
				/>
			</StandardDisplayRow>
			<StandardDisplayRow>
				<SingleStandardField
					header={t('standard.singleStandardPage.general.type')}
					value={
						props.standardTypes ? props.standardTypes.map(({ name }) => name).join(', ') : null
					}
					fallbackText={t('standard.singleStandardPage.general.typeAbsent')}
				/>
				<SingleStandardField
					header={t('standard.singleStandardPage.general.pages')}
					value={props.pages}
					fallbackText={t('standard.singleStandardPage.general.pagesAbsent')}
				/>
			</StandardDisplayRow>
			<StandardDisplayRow>
				<DomainsWgroups
					domains={props.domains}
					workingGroups={props.workingGroups}
					isWorkingGroupNull={props.isWorkingGroupNull}
				/>
			</StandardDisplayRow>
			<StandardDisplayRow>
				<SingleStandardField
					header={t('standard.singleStandardPage.general.cenWiNumber')}
					value={props.cenWiNumber}
					fallbackText={t('standard.singleStandardPage.general.cenWiNumberAbsent')}
				/>
				<StandardDisplayField>
					<StandardDisplayFieldHeader>
						{t('standard.singleStandardPage.general.projectLeader')}{' '}
						{!!props.projectLeader?.deletedAt}
					</StandardDisplayFieldHeader>
					{props.projectLeader ? (
						<StandardDisplayFieldValue>
							<ProjectLeader user={props.projectLeader} />
						</StandardDisplayFieldValue>
					) : (
						<StandardDisplayFieldValueAbsent>
							{t('standard.singleStandardPage.general.projectLeaderAbsent')}
						</StandardDisplayFieldValueAbsent>
					)}
				</StandardDisplayField>
			</StandardDisplayRow>

			{!!props.titles && (
				<>
					<TitleContainer key={`${titlesKeys[0]}`}>
						<StandardDisplayFieldHeader>{`${t(
							'standard.singleStandardPage.titles.standardTitleIn'
						)} ${languageNames.of(titlesKeys[0])}`}</StandardDisplayFieldHeader>
						{titles[0] ? (
							<StandardDisplayFieldValue key={titles[0]}>{titles[0]}</StandardDisplayFieldValue>
						) : (
							<StandardDisplayFieldValueAbsent>
								{t('standard.singleStandardPage.titles.titleAbsent')}
							</StandardDisplayFieldValueAbsent>
						)}
					</TitleContainer>
					<TitleContainer key={`${titlesKeys[2]}`}>
						<StandardDisplayFieldHeader>{`${t(
							'standard.singleStandardPage.titles.standardTitleIn'
						)} ${languageNames.of(titlesKeys[2])}`}</StandardDisplayFieldHeader>
						{titles[2] ? (
							<StandardDisplayFieldValue key={titles[2]}>{titles[2]}</StandardDisplayFieldValue>
						) : (
							<StandardDisplayFieldValueAbsent>
								{t('standard.singleStandardPage.titles.titleAbsent')}
							</StandardDisplayFieldValueAbsent>
						)}
					</TitleContainer>
					<TitleContainer key={`${titlesKeys[1]}`}>
						<StandardDisplayFieldHeader>{`${t(
							'standard.singleStandardPage.titles.standardTitleIn'
						)} ${languageNames.of(titlesKeys[1])}`}</StandardDisplayFieldHeader>
						{titles[1] ? (
							<StandardDisplayFieldValue key={titles[1]}>{titles[1]}</StandardDisplayFieldValue>
						) : (
							<StandardDisplayFieldValueAbsent>
								{t('standard.singleStandardPage.titles.titleAbsent')}
							</StandardDisplayFieldValueAbsent>
						)}
					</TitleContainer>
				</>
			)}

			<StandardDisplayRow>
				<SingleStandardField
					header={t('standard.singleStandardPage.general.scope')}
					value={props.scope}
					fallbackText={t('standard.singleStandardPage.general.scopeAbsent')}
				/>
			</StandardDisplayRow>
		</StandardDisplayContainer>
	);
});
