import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import {
	getStandardDetailsService,
	getStandardService,
} from '@asd-stan/standard/infrastructure/getters';
import { SingleStandardScheme } from '@asd-stan/standard/validation-schemas/single-standard.schema';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { Loading } from '@components/loading/loading';
import { Flex } from '@components/utility/flex';
import { Formik, FormikValues } from 'formik';

import { AdditionalInformation } from './page-blocks/additional-information/additional-information';
import { ENData } from './page-blocks/enData/enData-draft-tab-section';
import { Experts } from './page-blocks/experts/experts';
import { General } from './page-blocks/general/general';
import { Justification } from './page-blocks/justification/justification';
import { Originator } from './page-blocks/originator/originator';
import { RegistrationData } from './page-blocks/reg-data/reg-data';
import { Remarks } from './page-blocks/remarks/remarks';
import { StandardDraft } from './page-blocks/standard-draft/standard-draft';
import { StyledLoaderContainer } from './single-standard.styled';

const initialValues = {
	remark: '',
};

export const SingleStandardPageTabSection: React.FC = observer(() => {
	const { t } = useTranslation();
	const standardService = getStandardDetailsService();
	const singleStandardService = getStandardService();
	const currentUserService = getCurrentUserService();

	if (!standardService.standard || !singleStandardService.singleStandard) {
		return (
			<StyledLoaderContainer>
				<Flex $align="center" $justify="center" $direction="column">
					<LogoIcon />
					<Loading horizontal />
				</Flex>
			</StyledLoaderContainer>
		);
	}

	const onRemarksSubmit = (values: FormikValues) => {
		console.log(values);
	};

	return (
		<>
			<RegistrationData
				createdAt={standardService.standard.createdAt}
				updatedAt={standardService.standard.updateAt}
			/>
			<InputGroup title={t('standard.singleStandardPage.general.title')}>
				<General
					form={standardService.standard.form}
					edition={standardService.standard.edition}
					stanNumber={standardService.standard.stanNumber}
					registrationNumber={standardService.standard.registrationNumber}
					revision={standardService.standard.revision}
					languages={standardService.standard.languages}
					pages={standardService.standard.pages}
					cenWiNumber={standardService.standard.cenWiNumber}
					workingGroups={standardService.standard.workingGroups}
					standardTypes={standardService.standard.standardTypes}
					domains={standardService.standard.domains}
					scope={standardService.standard.scope}
					projectLeader={standardService.standard.leader}
					titles={standardService.standard.titles}
				/>
			</InputGroup>
			{standardService.standard.originator &&
				(standardService.standard.originator.id || standardService.standard.originator.userId) && (
					<Originator originator={standardService.standard.originator} />
				)}
			<StandardDraft
				standardId={standardService.standard.id}
				data={standardService.standard.file}
			/>
			<Justification
				standardName={`${standardService.standard?.form} ${standardService.standard?.registrationNumber} ${standardService.standard?.edition}`}
				data={standardService.standard.justification}
			/>
			<AdditionalInformation
				tags={standardService.standard.tags}
				classifications={standardService.standard.classifications}
				ICSCodes={standardService.standard.icsCodes}
			/>
			<Experts data={standardService.standard.experts} wgs={standardService.standard.wgs} />
			<ENData data={standardService.standard.enData} />
			<Formik
				validationSchema={SingleStandardScheme}
				initialValues={initialValues}
				onSubmit={onRemarksSubmit}>
				<InputGroup
					title={t('standard.createNWP.remarks.title')}
					children={
						<Remarks
							skipRemarksSendCheck={false}
							user={currentUserService.currentUser}
							domainParticipations={currentUserService.domainParticipations}
							experts={standardService.standard.experts}
							originator={standardService.standard.originator}
							remarks={singleStandardService.singleStandard.remarks}
						/>
					}
				/>
			</Formik>
		</>
	);
});
