import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BallotForm } from '@asd-stan/ballot/domain/ballot.entity';
import { calculateOffset } from '@asd-stan/helpers/app-utils';
import { ProjectLeader } from '@asd-stan/standard/components/general/project-leader/project-leader';
import {
	getStandardPublicService,
	getStandardService,
} from '@asd-stan/standard/infrastructure/getters';
import { InputGroup } from '@asd-stan/user/components/layout/input-group/input-group';
import { UserCollectionFilter } from '@asd-stan/user/domain/user.service';
import { FormDatePicker } from '@components/form-date-picker/form-date-picker';
import { FormInputParagraph } from '@components/form-input-paragraph/form-input-paragraph';
import { FormInput } from '@components/form-input/form-input';
import { FormSelect } from '@components/form-select/form-select';
import {
	AdditionalType,
	FormSelectAsyncPagination,
} from '@components/form-select/form-select-async-pagination';
import { Field, useFormikContext } from 'formik';
import moment from 'moment';

const limit = 6;

export const General = observer(() => {
	const { t } = useTranslation();
	const standardService = getStandardService();
	const standardPublicService = getStandardPublicService();
	const { values, setFieldValue } = useFormikContext<BallotForm>();

	const handleLoadLatestStandards = async (
		search: string,
		_prevOptions: any,
		{ page }: AdditionalType
	) => {
		const offset = calculateOffset(page, limit);
		const count = await standardService.getLatestStandardsList(limit, offset, {
			childSearch: true,
			registrationNumber: search,
			isDraft: false,
		});
		const updateStandards = standardService.latestStandards.map(standard => {
			return {
				label: `${standard.registrationNumber ?? ''} ${standard.form ?? ''} ${
					standard.edition ?? ''
				} ${standard.localizedTitle}`,
				value: standard.id,
			};
		});
		const hasMore = page < Math.ceil(count / 6);
		return {
			options: updateStandards.filter(
				standard => standard.value !== standardService.singleStandard?.id
			),
			hasMore,
			additional: {
				page: page + 1,
			},
		};
	};

	const handleLoadUsersStandards = async (
		search: string,
		_prevOptions: any,
		{ page }: AdditionalType
	) => {
		const filter: Partial<UserCollectionFilter> = {
			search: !!search ? search : undefined,
		};

		const offset = calculateOffset(page, limit);
		const userList = await standardPublicService.getUsersByDomains(filter, limit, offset);

		const users = userList.data.map(user => {
			return {
				label: `${user.firstName} ${user.lastName}`,
				userLabel: <ProjectLeader user={user} />,
				value: user.id,
			};
		});
		const hasMore = page < Math.ceil(userList.totalNumber / limit);

		return {
			options: users,
			hasMore,
			additional: {
				page: page + 1,
			},
		};
	};

	const expertsOptions = standardPublicService.userPublicList.map(user => {
		return {
			label: user.fullName,
			value: user.id,
			roles: user.systemRoles.map((role, index) => <span key={index}>{role}</span>),
		};
	});

	return (
		<InputGroup title={t('ballot.createBallot.general')}>
			<Field
				component={FormInputParagraph}
				name="title"
				title={t('ballot.createBallot.titleField')}
				mandatory
				fullWidth
				showError
			/>
			<Field
				component={FormInputParagraph}
				name="description"
				title={t('ballot.createBallot.description')}
				fullWidth
				showError
				maxLength={5000}
			/>
			<Field
				component={FormSelectAsyncPagination}
				name="standard"
				title={t('ballot.createBallot.standard')}
				loadOptions={handleLoadLatestStandards}
				fullWidth
				showError
			/>
			<Field
				component={FormDatePicker}
				name="openingDate"
				label={t('ballot.createBallot.openingDate')}
				showError
				onChange={(_: string, date: Date) => {
					if (moment(values.closingDate).isBefore(date)) {
						setFieldValue('closingDate', null);
					}
				}}
				placeholder={t('ballot.createBallot.datePlaceholder')}
			/>
			<Field
				component={FormDatePicker}
				name="closingDate"
				label={t('ballot.createBallot.closingDate')}
				showError
				minDate={values.openingDate}
				placeholder={t('ballot.createBallot.datePlaceholder')}
			/>
			<Field
				component={FormSelectAsyncPagination}
				name="selectedVoters"
				title={t('ballot.createBallot.voters')}
				options={expertsOptions}
				fullWidth
				defaultOptions
				showCheckboxOption
				showError
				mandatory
				isMulti
				loadOptions={handleLoadUsersStandards}
				showAvatar
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
			/>
		</InputGroup>
	);
});
