import { UploadedFile } from '@asd-stan/file/domain/file-upload-controller';

export enum BallotStatus {
	Pending = 'pending',
	Active = 'active',
	Closed = 'closed',
}

export enum BallotResult {
	Pending = 'pending',
	Approved = 'approved',
	Disapproved = 'disapproved',
	NotValid = 'notValid',
}

export interface BallotForm {
	status: BallotStatus;
	title: string;
	description: string;
	standard: {
		label: string;
		value: number;
	} | null;
	openingDate: Date | null;
	closingDate: Date | null;
	selectedVoters: { label: string | JSX.Element; value: number }[];
	result: { label: string; value: BallotResult } | null;
	resultComment: string;
	attachments: UploadedFile[];
	questions: {
		id: number | null;
		localId: string;
		title: string;
		comment: string;
	}[];
}

export interface ValidatedBallotForm {
	status: BallotStatus;
	title: string;
	description: string;
	standard: {
		label: string;
		value: number;
	} | null;
	openingDate: Date;
	closingDate: Date;
	selectedVoters: { label: string | JSX.Element; value: number }[];
	result: { label: string; value: BallotResult };
	resultComment: string;
	attachments: {
		id: number;
		fileId: number;
		title: string;
		path: string;
		size: number;
	}[];
	questions: {
		id: number | null;
		localId: string;
		title: string;
		comment: string;
	}[];
}

export interface SingleBallotToUpdate {
	createdById: number;
	form: ValidatedBallotForm;
}

export interface BallotListItem {
	id: number;
	title: string;
	description: string;
	status: BallotStatus;
	openingDate: string;
	closingDate: string;
	result: BallotResult;
	resultComment: string;
	cratedById: number;
}

export interface BallotListFilter {
	search?: string | null;
	orderField?: null | string;
	orderDirection?: 'DESC' | 'ASC';
	standardId?: number;
	openingDate?: null | {
		from?: null | Date;
		to?: null | Date;
	};
	results: Record<BallotResult, boolean>;
	myDashboard?: null | boolean;
}

export interface WithTimestamp<T> {
	value: T;
	doneBy: {
		firstName: string;
		lastName: string;
	};
	doneAt: Date;
}

export enum VotingDecision {
	Approve = 'approve',
	Abstain = 'abstain',
	Disapprove = 'disapprove',
}

export interface Decision {
	id: null | number;
	questionId: number;
	decision: null | VotingDecision;
	comment: string;
}

export interface SavedFile {
	id: number;
	fileId: number;
	title: string;
	path: string;
	size: number;
}

export interface VotingForm {
	organization: null | {
		label: string;
		value: number;
	};
	attachments: UploadedFile[];
	decisions: Decision[];
}

export interface ValidatedVotingForm {
	organization: {
		label: string;
		value: number;
	};
	attachments: SavedFile[];
	decisions: {
		id: null | number;
		questionId: number;
		decision: VotingDecision;
		comment: string;
	}[];
}

export interface MyVote {
	id: number;
	form: ValidatedVotingForm;
}

export interface Question {
	id: number;
	title: string;
	comment: string;
}

export interface SingleBallot {
	title: string;
	status: BallotStatus;
	description: string;
	standard: {
		id: number;
		registrationNumber: string;
		form: string;
		edition: string;
		title: string;
	} | null;
	openingDate: Date;
	closingDate: Date;
	attachments: WithTimestamp<SavedFile>[];
	result: WithTimestamp<BallotResult>;
	resultComment: WithTimestamp<string>;
	selectedVotersIds: number[];
	questions: Question[];
	myVote: null | MyVote;
	createdById: number;
}

export type VoteListItem = WithTimestamp<{
	id: number;
	organizationName: string;
	decisions: {
		id: number;
		question: {
			title: string;
			comment: string;
		};
		decision: VotingDecision;
		comment: string;
	}[];
	attachments: WithTimestamp<SavedFile>[];
}>;

export enum VotingDecisionResult {
	Approved = 'approved',
	Abstain = 'abstain',
	Disapproved = 'disapproved',
	Pending = 'pending',
}

export interface BallotStats {
	questionId: number;
	questionTitle: string;
	result: VotingDecisionResult;
	totalVoted: number;
	allowedToVote: number;
	approve: number;
	disapprove: number;
	abstain: number;
}
