import { makeAutoObservable } from 'mobx';

import { Form } from './enums';

export interface Money {
	amount: number;
	currency: string;
}

export interface StandardType {
	name: string;
}

export interface DocumentPreview {
	id: number;
	path: string;
	size: number;
	name: string;
}

export interface Language {
	name: string;
}

export interface CatalogItemArgs {
	id: number;
	standardId: number;
	name: string;
	title: string;
	publicationDate: string;
	availableForPurchase: boolean;
	localizedTitle: string;
	price: Money;
	published: boolean;
	description: string;
	coverPage: DocumentPreview;
	show: boolean;
	isTop: boolean;
	form: Form;
	registrationNumber: string;
	types: { id: number; name: string }[];
	edition: string;
	languages: string[];
	domains: {
		name: string;
		code: string;
	}[];
	scope: string;
	internalReferences: { id: number; name: string }[];
	externalReferences: string;
	tags: string[];
	status: string;
	publisher: string;
	preview: null | {
		fileId: number;
		path: null | string;
		size: number;
		title: string;
	};
}

export class CatalogItem {
	id: number;
	standardId: number;
	name: string;
	title: string;
	publicationDate: string;
	availableForPurchase: boolean;
	localizedTitle: string;
	price: Money;
	description: string;
	coverPage: DocumentPreview;
	published: boolean;
	show: boolean;
	isTop: boolean;
	form: Form;
	registrationNumber: string;
	types: { id: number; name: string }[];
	edition: string;
	languages: string[];
	domains: {
		name: string;
		code: string;
	}[];
	scope: string;
	internalReferences: { id: number; name: string }[];
	externalReferences: string;
	tags: string[];
	status: string;
	publisher: string;
	preview: null | {
		fileId: number;
		path: null | string;
		size: number;
		title: string;
	};

	constructor(args: CatalogItemArgs) {
		makeAutoObservable(this);

		this.id = args.id;
		this.standardId = args.standardId;
		this.name = args.name;
		this.title = args.title;
		this.publicationDate = args.publicationDate;
		this.availableForPurchase = args.availableForPurchase;
		this.localizedTitle = args.localizedTitle;
		this.price = args.price;
		this.domains = args.domains;
		this.tags = args.tags;
		this.description = args.description;
		this.coverPage = args.coverPage;
		this.published = args.published;
		this.show = args.show;
		this.isTop = args.isTop;
		this.form = args.form;
		this.registrationNumber = args.registrationNumber;
		this.types = args.types;
		this.edition = args.edition;
		this.languages = args.languages;
		this.scope = args.scope;
		this.internalReferences = args.internalReferences;
		this.externalReferences = args.externalReferences;
		this.status = args.status;
		this.publisher = args.publisher;
		this.preview = args.preview;
	}
}
